import { destroyCookie, parseCookies } from 'nookies';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminTechnicalError } from 'src/components/admin/adminTechnicalError';
import { AdminNoInternet } from 'src/components/admin/noInternet';
import { DesktopRoomEntrance } from 'src/components/roomEntrance';
import { Logo } from 'src/elements/logo';
import { enterToTheGame } from 'src/Logic/API/operator/enterToTheGame';
import { ErrorCodes } from 'src/Logic/Models/General/technicalErrors';
import { OperatorRequester } from 'src/Logic/Requesters/OperatorRequester';
import { Page } from '../Admin/styles';

export const OperatorSignInPage = () => {
    const [technicalError, setTechnicalError] = useState<ErrorCodes | number | null>(null);
    const [roomCode, setRoomCode] = useState<string>();
    const [isNoInternet, setIsNoInternet] = useState(false);
    const requester = useMemo<OperatorRequester>(() => new OperatorRequester(), []);
    const navigate = useNavigate();

    useEffect(() => {
        const cookies = parseCookies();
        const storedRoomCode = cookies.roomCode;

        if (storedRoomCode) destroyCookie(null, storedRoomCode);
    }, []);

    const handleEnterToTheGame = () => {
        if (isNoInternet) setIsNoInternet(false);
        if (roomCode !== undefined)
            enterToTheGame(roomCode, requester, setTechnicalError, setIsNoInternet, navigate);
    };

    return (
        <Page>
            <Logo className="descktopLogo" />
            {!technicalError && !isNoInternet ? (
                <DesktopRoomEntrance
                    onButtonClick={handleEnterToTheGame}
                    onEnterCode={setRoomCode}
                    title="OPERATOR CONSOLE"
                    buttonText="START GAME"
                />
            ) : null}
            {technicalError ? (
                <AdminTechnicalError
                    error={technicalError}
                    onOk={() => {
                        setTechnicalError(null);
                    }}
                />
            ) : null}
            {isNoInternet ? <AdminNoInternet onRetry={handleEnterToTheGame} /> : null}
        </Page>
    );
};
