import { Button } from "src/elements/buttons";
import { Label } from "src/elements/label";
import { Text } from "src/elements/text";
import { AnswerStates } from "src/Logic/Hooks/useParticipantLogic";
import styled from "styled-components";

const Div = styled.div`
    z-index: 1;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;

    .categoryLabel {
        margin-bottom: ${ props => props.theme.spacing.small};
    }

    .roundNumber {
        font-size: 20px;
        line-height: 24px;
    }

    .question {
        margin-top: 8px;
        margin-bottom: 16px;
        line-height: 20px;
        text-align: center;
    }
    
    .answeredMessage {
        margin-top: 8px;
        width: 250px;
        text-align: center;
        line-height: 20px;

    }
`;

type AnswerType = {
    text: string,
    letter: string,
}

type Props = {
    category?: string,
    answers?: AnswerType[],
    onAnswer?: (value: string ) => void,
    roundNumber: string | number,
    question: string,
    answered: AnswerStates,
}

export const AnswersScreen = ({ answers, category, onAnswer, question, roundNumber, answered }: Props) => {

    const handleAnswerChoose = ( value: string) => { 
        if( onAnswer )
            onAnswer( value)
    }

    console.log(answered)

    return (
        <Div>
            <Label className = "categoryLabel">{ category }</Label>
            <Text className = "roundNumber">{`ROUND ${ roundNumber }`}</Text>
            { answered === AnswerStates.NotAnswered ? <Text className = "question">{ question }</Text> : null }
            { answered === AnswerStates.NotAnswered  ? answers?.map(( answer ) => {
                return <Button className = 'mrBottomSmall' variant="secondary" onClick = { () => { handleAnswerChoose( answer.letter ) } }>{ answer.text }</Button>
            }) 
            : 
            null }
            { answered === AnswerStates.Waiting ? <Text className = "answeredMessage">Your answer has been sent. Please wait for other players.</Text> : null }
            { answered === AnswerStates.Correct ? <><Text className = "answeredMessage">Congrats!</Text><Text>Your answer is correct!</Text></> : null}
            { answered === AnswerStates.Wrong ? <><Text className = "answeredMessage">Sorry!</Text><Text>Your answer is incorrect.</Text></>  : null}

        </Div>
    )
}