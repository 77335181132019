// Core
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

// Components
import { StudioStartGameScreen } from 'src/components/studio/infoScreens/StartScreen';
import { StudioWelComeScreen } from 'src/components/studio/welcomeScreen';
import { StudioWagerEliminationScreen } from 'src/components/studio/infoScreens/wagerEliminationScreen';
import { StudioRoundScreen } from 'src/components/studio/infoScreens/StudioRoundScreen';
import { StudioCategorySelection } from 'src/components/studio/studioCategorySelection';
import { MakingWagersScreen } from 'src/components/studio/infoScreens/makingWagersScreen';
import { StudioTechnicalError } from 'src/components/studio/infoScreens/technicalError';
import { StudioNoInternet } from 'src/components/studio/infoScreens/noInternetConnection';
import { FinalBindStudioScreen } from 'src/components/studio/infoScreens/finalBind';

// Elements
import { Logo } from 'src/elements/logo';

// Image
import BackGround from '../../assets/images/main-bg.png';

// Types
import { MonitorGameStatus } from 'src/Logic/Models/Response/monitor/gameStatus';
import { GameStatuses } from 'src/Logic/Models/General/gameStatuses';
import { MonitorRequester } from 'src/Logic/Requesters/MonitorRequester';

// Nookies
import { parseCookies } from 'nookies';

// Styled
import { Page } from './styles';

// Enum
import { ErrorCodes } from 'src/Logic/Models/General/technicalErrors';

// React router
import { NavigateFunction, useNavigate } from 'react-router-dom';

// Helpers
import { makeStats } from 'src/Logic/Helpers/studioGameStats';
import { WhoIsLeft } from 'src/components/studio/whoIsLeft';

const GAME_ID = 4;

export type StatType = {
    text: string;
    value: string | number;
};

async function updateState(
    roomCode: string | undefined,
    requester: MonitorRequester,
    setGameState: Dispatch<SetStateAction<MonitorGameStatus | undefined>>,
    setTechnicalError: Dispatch<SetStateAction<ErrorCodes | number | null>>,
    setIsNoInternet: Dispatch<SetStateAction<boolean>>,
    navigate: NavigateFunction,
) {
    if (!navigator.onLine) setIsNoInternet(true);
    else if (roomCode) {
        // console.log('Update status...');
        try {
            const response = await requester.GetGameStatus(GAME_ID, roomCode);
            if (response) {
                if (response.status === 200) {
                    const data = await response.json();
                    setGameState(data);
                } else if (response.status === 401) navigate('/sign-in');
                else if (response.status === 400) {
                    const body = await response.json();
                    setTechnicalError(body.errorCode);
                } else setTechnicalError(response.status);
            }
            // console.log('Status updated.');
        } catch (error) {
            console.log(error);
        }
    }
}

export const StudioMonitorPage = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [gameState, setGameState] = useState<MonitorGameStatus | undefined>();
    const [technicalError, setTechnicalError] = useState<ErrorCodes | number | null>(null);
    const [roomCode, setRoomCode] = useState<string | undefined>(undefined);
    const [isNoInternet, setIsNoInternet] = useState(false);

    const requester = useMemo<MonitorRequester>(() => new MonitorRequester(), []);
    const navigate = useNavigate();

    useEffect(() => {
        async function load() {
            const cookies = parseCookies();
            const storedRoomCode = cookies.roomCode;
            setRoomCode(storedRoomCode ? storedRoomCode : undefined);
            await updateState(
                storedRoomCode,
                requester,
                setGameState,
                setTechnicalError,
                setIsNoInternet,
                navigate,
            );
            setLoading(false);
        }
        load();
    }, [requester]);

    useEffect(() => {
        if (!isNoInternet && !technicalError) {
            let t = setTimeout(async function run() {
                try {
                    await updateState(
                        roomCode,
                        requester,
                        setGameState,
                        setTechnicalError,
                        setIsNoInternet,
                        navigate,
                    );
                } finally {
                    clearTimeout(t);
                    t = setTimeout(run, 1000);
                }
            }, 1000);
            return () => {
                clearTimeout(t);
            };
        }
    }, [requester, roomCode, isNoInternet, technicalError]);

    const renderFunc = (game: MonitorGameStatus | undefined) => {
        if (technicalError)
            return (
                <StudioTechnicalError
                    error={technicalError}
                    onOk={() => {
                        setTechnicalError(null);
                    }}
                />
            );

        if (isNoInternet)
            return (
                <StudioNoInternet
                    onRetry={() => {
                        setIsNoInternet(false);
                    }}
                />
            );

        if (!game) return;
        switch (game.gameStatus) {
            case GameStatuses.enteringRoom:
                if ((game.joinedParticipantCount ?? 0) < game.maxParticipantCount)
                    return <StudioWelComeScreen roomCode={game?.roomCode} />;
                else
                    return (
                        <StudioStartGameScreen
                            participantCount={game.joinedParticipantCount ?? 0}
                        />
                    );
            case GameStatuses.selectingCategory:
                return (
                    <StudioCategorySelection
                        round={game?.roundNumber}
                        player={game?.participantName}
                        categories={game?.possibleCategories}
                        isFinalRound={game.isFinalRound}
                    />
                );
            case GameStatuses.makingWagers:
                return (
                    <MakingWagersScreen
                        stats={makeStats(
                            game.prevRoundStats?.participantWithWagers,
                            game.prevRoundStats?.correctlyAnsweredParticipantNames,
                        )}
                        showStats={Boolean(game.prevRoundStats)}
                        round={game?.roundNumber}
                        category={game?.roundCategory}
                        isFinalRound={game.isFinalRound}
                    />
                );
            case GameStatuses.displayEliminated:
                if (game.isFinalRound)
                    return <FinalBindStudioScreen participants={game.participantWithWagers} />;
                else
                    return (
                        <StudioWagerEliminationScreen
                            wager={game?.eliminatedParticipantWager}
                            playerNames={game?.eliminatedParticipantNames}
                        />
                    );
            case GameStatuses.answering:
                return (
                    <StudioRoundScreen
                        round={game?.roundNumber}
                        category={game?.roundCategory}
                        question={game?.question}
                        possibleAnswers={game?.possibleAnswers}
                    />
                );
            case GameStatuses.checkingAnswers:
            case GameStatuses.displayWinner:
                return (
                    <StudioRoundScreen
                        round={game?.roundNumber}
                        answer={`${game?.correctAnswer?.letter}. ${game?.correctAnswer?.text}`}
                        category={game?.roundCategory}
                        correctAnswersCount={
                            game.gameStatus === GameStatuses.checkingAnswers
                                ? game?.correctAnswerCount ?? 0
                                : undefined
                        }
                        question={game?.question}
                        winnerNames={game?.winnerNames}
                        winnerBalance={game.winnerBalance}
                        winnersCount={game.winnerNames?.length}
                        correctlyAnsweredParticipantNames={
                            game.isFinalRound ? undefined : game.correctlyAnsweredParticipantNames
                        }
                        // isFinalRound={game.isFinalRound}
                    />
                );
            default:
                return <></>;
        }
    };
    const styles = !gameState
        ? {}
        : {
              backgroundImage: `url(${BackGround})`,
              backgroundRepeat: 'round',
              backgroundSize: 'cover',
              backgroundColor: '#000',
          };
    const gameStatus = gameState?.gameStatus;
    const showActivePlayers =
        gameStatus === GameStatuses.enteringRoom ||
        (gameStatus === GameStatuses.displayEliminated && !gameState?.isFinalRound);

    return (
        <Page style={styles}>
            <Logo className="desktopLogo" />
            {showActivePlayers ? <WhoIsLeft names={gameState?.currentParticipantNames} /> : null}
            {!loading ? renderFunc(gameState) : <></>}
            <div className='bottomGradient'/>
        </Page>
    );
};
