const regionFormatString = "en-US";

export function ToDisplayNumber(value: number | undefined | null): string {
    if (value === undefined || value === null || !Number.isInteger(value)) return '';
    var result = Intl.NumberFormat(regionFormatString).format(Math.floor(value));
    // console.error("test", result);
    return result;
}

export function FromDisplayNumber(value: string | undefined | null): number {
    if (value === undefined || value === null) return 0;
    value = value.trim();
    if (value === '') return 0;

    const thousandSeparator = Intl.NumberFormat(regionFormatString).formatToParts(11111)[1].value;
    const decimalSeparator = Intl.NumberFormat(regionFormatString).formatToParts(1.1)[1].value;

    console.log(value, thousandSeparator, decimalSeparator);

    const result = parseFloat(value
        .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
        .replace(new RegExp(`\\${decimalSeparator}`), '.')
    );

    return result;
}

