import { Button } from "src/elements/buttons"
import { Text } from "src/elements/text"
import { ErrorCodes } from "src/Logic/Models/General/technicalErrors"
import { StudioInfoScreen } from "../StudioInfoScreen"

type Props = {
    onOk: () => void;
    error: ErrorCodes | number | null;
}

export const StudioTechnicalError = ({ onOk, error }: Props ) => {
    return (
        <StudioInfoScreen>
            <Text className = "primary mrBottomExtraLarge" >A technical error occurred. { error } </Text>
            <Button onClick = { onOk } className = "studioBigButton" variant = "gradient" >OK</Button>
        </StudioInfoScreen>
    )
}