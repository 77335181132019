import { Button } from "src/elements/buttons"
import { FinalBidLogo } from "src/elements/finalBidLogo"
import { Text } from "src/elements/text"
import { StudioInfoScreen } from "../infoScreens/StudioInfoScreen"


type Props = {
    round?: string | number,
    categories?: string[] | undefined,
    player?: string,
    isFinalRound?: boolean,
}

export const StudioCategorySelection = ({ round, categories, player, isFinalRound }: Props) => {
    return (
        <StudioInfoScreen>
             { isFinalRound ? <div className = 'finalBitLogoContainer'>
                                <FinalBidLogo className = "studioFinalBitLogo"/>
                            </div> : null }
            <Text className="primary mrBottomExtraLarge" >{`Round ${round}`}</Text>
            <Text className="primary mrBottomExtraLarge">{player} is selecting category...</Text>
            {categories ? categories.map((category) => <Button variant="gradient" style={{ width: '547px', cursor: 'default' }} className="mrBottomMain descktopLabelButton">{category}</Button>) : null}
        </StudioInfoScreen>
    )
}