// React-router
import { Route, Routes } from 'react-router-dom';

// Helpers
import { AppMode, APP_MODE } from 'src/Logic/Helpers/config';

// Pages
import { AdminPage } from 'src/pages/Admin';
import { AudiencePage } from 'src/pages/Audience';
import { HomePage } from 'src/pages/Home';
import { PlayersPage } from 'src/pages/Players';
import { AudienceSignInPage } from 'src/pages/SignIn/audienceSignIn';
import { OperatorSignInPage } from 'src/pages/SignIn/operatorSignIn';
import { PlayerSignInPage } from 'src/pages/SignIn/playerSignIn';
import { StudioSignInPage } from 'src/pages/SignIn/studioSignIn';
import { StudioMonitorPage } from 'src/pages/StudioMonitor';

export const RoutesSelector = () => {
    console.log(APP_MODE);
    const defaultRoutes = (
        <Routes>
            <Route path="/" element={<PlayersPage />} />
            <Route path="/studio" element={<StudioMonitorPage />} />
            <Route path="/admin" element={<AdminPage />} />
        </Routes>
    );

    const participantsRoutes = (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/sign-in" element={<PlayerSignInPage />} />
            <Route path="/game" element={<PlayersPage />} />
        </Routes>
    );

    const operatorRoutes = (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/sign-in" element={<OperatorSignInPage />} />
            <Route path="/game" element={<AdminPage />} />
        </Routes>
    );

    const monitorRoutes = (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/sign-in" element={<StudioSignInPage />} />
            <Route path="/game" element={<StudioMonitorPage />} />
        </Routes>
    );

    const audienceRoutes = (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/sign-in" element={<AudienceSignInPage />} />
            <Route path="/game" element={<AudiencePage />} />
        </Routes>
    );

    switch (APP_MODE) {
        case AppMode.Monitor:
            return monitorRoutes;
        case AppMode.Participant:
            return participantsRoutes;
        case AppMode.Operator:
            return operatorRoutes;
        case AppMode.Audience:
            return audienceRoutes;
        default:
            return defaultRoutes;
    }
};
