import { ErrorCodes } from "../Models/General/technicalErrors";
import { MonitorGameStatus } from "../Models/Response/monitor/gameStatus";
import { BaseRequester } from "./BaseRequester";

export class MonitorRequester extends BaseRequester {

    public async EnterToTheGame(id: string | number, roomCode: string): Promise<Response | undefined> {
        return await this.MakePutRequest(`/api/Monitor/enter`, { roomCode });
    }

    public async GetGameStatus(id: number | string, roomCode: string): Promise<Response | undefined> {
        const headers = new Headers();
        headers.append('roomCode', roomCode)
        return await this.MakeGetRequest(`/api/Monitor/state`, null, headers);
    }
}