import styled from 'styled-components';

const InputWrapper = styled.div`
    position: relative;
    width: 313px;
    font-family: 'Gotham A', 'Gotham B';
    z-index: 1;
    @media (max-width: 426px) {
        width: 100%;
    }

    span {
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: #000;
        text-transform: capitalize;
        margin-bottom: 8px;
        display: block;

        &.inputErrorSpan {
            position: absolute;
            top: 43px;
            color: ${props => props.theme.colors.red.main};
            font-size: 14px;
            line-height: 16px;
            text-transform: none;

            &.withLabel {
                top: 70px;
            }
        }
    }

    input {
        width: 100%;
        height: 40px;
        font-weight: bold;
        color: #000;
        border: 1px solid ${props => props.theme.colors.gray.input};
        box-sizing: border-box;
        border-radius: 4px;
        padding-left: 11px;
        letter-spacing: 0.01em;
        font-size: 14px;
        background-color: #fff;

        &.error {
            border-color: ${props => props.theme.colors.red.main};
            border-width: 2px;
            padding-left: 10px;
        }

        :focus-visible {
            outline: none;
        }

        ::placeholder {
            font-size: 14px;
            font-family: 'Gotham A', 'Gotham B';
            color: ${props => props.theme.colors.gray.main};
            letter-spacing: 0.01em;
            text-transform: capitalize;
        }
    }
`;

type Props = {
    placeholder?: string | undefined;
    label?: string | undefined;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string | undefined;
    error?: string | undefined | null;
    type?: React.HTMLInputTypeAttribute | undefined;
    maxLength?: number;
    value?: string;
};

export const Input = ({
    label,
    placeholder,
    onChange,
    className,
    error,
    type,
    maxLength,
    value,
}: Props) => {
    return (
        <InputWrapper className={className}>
            {label !== undefined ? <span>{label}</span> : null}
            <input
                type={type}
                className={error ? 'error' : ''}
                placeholder={placeholder}
                onChange={onChange}
                maxLength={maxLength}
                value={value}
            />
            {error !== undefined && error !== null ? (
                <span className={`inputErrorSpan${label ? ' withLabel' : ''}`}>{error}</span>
            ) : null}
        </InputWrapper>
    );
};
