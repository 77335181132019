import { useLayoutEffect, useRef, useState } from "react"
import { FinalBidLogo } from "src/elements/finalBidLogo"
import { Label } from "src/elements/label"
import { Text } from "src/elements/text"
import { StatType } from "src/pages/StudioMonitor"
import styled from "styled-components"
import { StudioInfoScreen } from "../StudioInfoScreen"
import { GameStat } from "./gameStat"

const StatsPanel = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: ${ props => props.theme.spacing.main };
    align-items: center;
    margin-top: 140px;
    width: 95vw;

    .statsRow {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
`;

type Props = {
    category?: string,
    round?: string | number, 
    isFinalRound?: boolean,
    stats: StatType[],
    showStats: boolean,
}

export const MakingWagersScreen = ({ category, round, isFinalRound, stats, showStats }: Props) => {

    const [ marginTop, setMarginTop ] = useState( 0 );
    const titleRef = useRef<HTMLDivElement>( null );

    useLayoutEffect(() => {
        document.body.style.overflow = 'hidden';
        if( titleRef.current && titleRef.current.offsetTop < 103)
            if( window.innerHeight >= document.body.scrollHeight) 
                setMarginTop( titleRef.current.offsetTop + 1 )
        return () => { document.body.style.overflow = 'unset'; }
    }, [ marginTop ]);

    return (
        <StudioInfoScreen>
            { isFinalRound ? <div className = 'finalBitLogoContainer'>
                                <FinalBidLogo className = "studioFinalBitLogo"/>
                            </div> : null }
            <Text wrapperRef = { titleRef } style={{ marginTop }} className = "primary mrBottomLarge">{`The category for ${`Round ${ round }`} is:`}</Text>
            <Label className = "categoryLabel mrBottomLarge">{ category }</Label>
            <Text className = "primary">Please make a wager!</Text>
            { showStats ? <StatsPanel>
                <Text className = 'answer fontSizeMedium' style = {{ marginBottom: 30 }}>Stats from the last round:</Text>
                <div className = 'statsRow'>
                    { stats.map(( s ) => <GameStat key = { s.text } stat = { s.text } value = { s.value } />)}
                </div>
            </StatsPanel> 
            : null }
        </StudioInfoScreen>
    )
}