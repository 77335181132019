import { Button } from "src/elements/buttons"
import { Header } from "src/elements/header";
import { Input } from "src/elements/input"

type Props = {
    onEnter: () => void,
    isRoomCodeWrong: boolean,
    setName: React.Dispatch<React.SetStateAction<string>>,
    setRoomCode: React.Dispatch<React.SetStateAction<string>>
    name: string,
    roomCode: string,
}

export const LogInScreen = ({ onEnter, isRoomCodeWrong, setName, setRoomCode, name, roomCode }: Props) => {

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.value !== undefined )
            setName(event.target.value.trim());
    }

    const onRoomCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.value !== undefined )
            setRoomCode(event.target.value.trim());
    }

    const handleEnter = () => {
        onEnter();
    }

    return ( 
        <>
            <Header />
                <div className = "inputWrapper">
                    <Input 
                        onChange = { onRoomCodeChange } 
                        error = { isRoomCodeWrong ? 'incorrect code.' : null } 
                        className = 'mrBottomMain' 
                        label = "Room Code" 
                        placeholder = "Enter 4 Letter Code"
                        maxLength={4}
                    />
                    <Input 
                        onChange = { onNameChange } 
                        className = 'mrBottomMain' 
                        label = 'your name (12 character limit)' 
                        placeholder = "Enter your Name"
                        maxLength={12}
                    />
                    <Button disabled = { name === '' || roomCode === ''} onClick = { handleEnter } style = {{ marginBottom: '14px'}}>Play</Button>
                    <span className = "termsConditions">by clicking Play, you agree to our</span>
                    <a href="#">Terms And Conditions</a>
            </div>
        </>

    )
}