import { destroyCookie, parseCookies } from 'nookies';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminTechnicalError } from 'src/components/admin/adminTechnicalError';
import { AdminNoInternet } from 'src/components/admin/noInternet';
import { DesktopRoomEntrance } from 'src/components/roomEntrance';
import { Logo } from 'src/elements/logo';
import { ErrorCodes } from 'src/Logic/Models/General/technicalErrors';
import { Page } from '../Admin/styles';
import { AudienceRequester } from 'src/Logic/Requesters/AudienceRequester';
import { enterToTheGame } from 'src/Logic/API/Audience/enterToTheGame';

export const AudienceSignInPage = () => {
    const [technicalError, setTechnicalError] = useState<ErrorCodes | number | null>(null);
    const [isRoomNotFound, setIsRoomNotFound] = useState<boolean>(false);
    const [roomCode, setRoomCode] = useState<string>();
    const [isNoInternet, setIsNoInternet] = useState(false);
    const requester = useMemo<AudienceRequester>(() => new AudienceRequester(), []);
    const navigate = useNavigate();

    useEffect(() => {
        const cookies = parseCookies();
        const storedRoomCode = cookies.roomCode;

        if (storedRoomCode) destroyCookie(null, storedRoomCode);
    }, []);

    const handleEnterToTheGame = () => {
        if (isNoInternet) setIsNoInternet(false);
        if (roomCode !== undefined)
            enterToTheGame({
                roomCode,
                requester,
                setTechnicalError,
                setIsNoInternet,
                setIsRoomNotFound,
                navigate,
            });
    };

    const handleRoomCodeChange = (code: string) => {
        isRoomNotFound && setIsRoomNotFound(false);
        setRoomCode(code);
    };

    const isAbleToStart = !!roomCode && roomCode.length >= 4 && !isRoomNotFound;

    return (
        <Page>
            <Logo className="descktopLogo" />
            {!technicalError && !isNoInternet ? (
                <DesktopRoomEntrance
                    onButtonClick={handleEnterToTheGame}
                    onEnterCode={handleRoomCodeChange}
                    title="AUDIENCE MONITOR"
                    buttonText="START GAME"
                    buttonDisabled={!isAbleToStart}
                    errorText={isRoomNotFound ? 'Room code was not found.' : undefined}
                />
            ) : null}
            {technicalError ? (
                <AdminTechnicalError
                    error={technicalError}
                    onOk={() => {
                        setTechnicalError(null);
                    }}
                />
            ) : null}
            {isNoInternet ? <AdminNoInternet onRetry={handleEnterToTheGame} /> : null}
        </Page>
    );
};
