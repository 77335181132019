import styled from "styled-components"

const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .categorySelected {
        width: 210px;
        line-height: 20px;
        margin-top: ${ props => props.theme.spacing.main };
    }

    .elimination {
        margin-top: 65px;
        width: 230px;
        line-height: 20px;
    }

    .winner {
        font-size: 16px;
    }

    .winnerBalance {
        font-size: 24px;
        margin-top: 10px;
    }
`;

type Props = {
    children?: any
}

export const InfoScreen = ({ children }: Props) => {
    return (
        <Div>
            { children }
        </Div>
    )
}