import { Button } from "src/elements/buttons";
import { Text } from "src/elements/text";
import { ErrorCodes } from "src/Logic/Models/General/technicalErrors";
import styled from "styled-components"

const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
        text-transform: uppercase;
        font-size: 40px;
        margin-bottom: ${ props => props.theme.spacing.extraLarge };
        margin-bottom: 60px;
    }
`;

type Props = {
    onOk: () => void;
    error:  ErrorCodes | number | null;
}
export const AdminTechnicalError = ({ onOk, error }: Props) => {

    return (
        <Div>
            <Text className = "title">A Technical Error occurred. { error }</Text>
            <Button onClick = { onOk } >OK</Button>
        </Div>
    )
}