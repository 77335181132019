import styled from "styled-components"
import { Text } from "../text";
import WinnerIcon from '../../assets/images/trophy.png';
import Ellipse from '../../assets/images/ellipse.png';
import { ToDisplayNumber } from "src/Logic/Helpers/number-helpers";

const Div = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .backgroundImg {
        /* max-width: 100%; */
        width: 100%;
        height: 94%;
        position: absolute;
        left:0;
        bottom: 0;
        right: 0;

        /* width: 100vw; */
    }

    img {
        /* position: absolute;
        left: 50%; */

    }

    .textWrapper {
        display: flex;
        flex-direction: column;
        z-index: 1; 
        padding-top: 34px;
        margin-right: ${props => props.theme.spacing.main};
        .winnerText {
            font-size: 30px;
            margin-bottom: 14px;
            color: ${ props => props.theme.colors.green.main };
            z-index: 1;
    
        }
        .winnerName {
            color: ${ props => props.theme.colors.black };
            font-size: 50px;
            z-index:1;
        }

        .winnerBalance {
            color: black;
            font-size: 50px;
        }
    }
    .dFlex {
        display: flex;
        
        .winnerContainer {
            margin-right: 20px;
        }

        > * {
            &:last-child {
                margin: 0px;
        }
    }
    }

`;

type Props = {
    names?: string[],
    winnersCount?: number,
    winnerBalance?: number | undefined,
}

export const Winner = ({ names, winnersCount, winnerBalance }: Props) => {
    return (
        <Div>
            <div className = "textWrapper">
                <Text className = "winnerText" >Winner{ winnersCount && winnersCount > 1 ? 's' : ''}</Text>
                <div className = "dFlex">
                    {names?.map((name, index) => { return (
                        <div className = 'winnerContainer'>
                            {/* <Text className = "winnerName" >{ `${ name }${winnersCount && winnersCount > 1 && index !== winnersCount - 1  ? ', ' : ''}`}</Text>  */}
                            <Text className = "winnerName" >{ name }</Text> 
                            <Text className = "winnerBalance">{`$${ToDisplayNumber(winnerBalance)} `}</Text>
                        </div>
                    )})}
                </div>
                
            </div>
            <img  style = {{ zIndex: 1 }} src = { WinnerIcon } alt = "" />
            <img className = 'backgroundImg' src = { Ellipse } alt = "" />
        </Div>
    )
} 