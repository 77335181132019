import { GameStatuses } from 'src/Logic/Models/General/gameStatuses';
import { makePlayersData } from 'src/components/admin/playersRow/helpers/makePlayersData';
import { Player } from 'src/elements/player';
import { PlayerStairsContainer } from './styles';
import { AudienceParticipantDataType } from 'src/Logic/Models/Response/audience/gameStatus';
import { AnswerType } from 'src/Logic/Models/Response/operator/gameStatus';

const PLAYERS_STAIR_MARGIN = 20;

type Props = {
    participants: AudienceParticipantDataType[] | undefined;
    gameStatus: GameStatuses,
};

export const PlayersStairs = ({ participants, gameStatus }: Props) => {
    const sortedParticipants = participants?.sort((leftParticipant, rightParticipant) => {
        let comapationResult = 0;
        if (leftParticipant.balance < rightParticipant.balance) comapationResult = 1;
        else if (leftParticipant.balance > rightParticipant.balance) comapationResult = -1;
        return comapationResult;
    });

    const wagers = sortedParticipants?.map(({ id, wager }) => {return { id, wager }});
    const answers: AnswerType[] = sortedParticipants?.filter(({ answerCode }) => answerCode )
        .map(({ id, answerCode }) => {return { id, letter: answerCode }}) as  AnswerType[];
    const correctAnswer = sortedParticipants?.find(({ isCorrectAnswer }) => isCorrectAnswer)?.answerCode;
    const isCheckingAnswersStatus = gameStatus === GameStatuses.checkingAnswers;

    const playersData = sortedParticipants
        ? makePlayersData({
              participants: sortedParticipants,
              answers: answers,
              wagers: wagers || [],
              gameStatus: gameStatus,
          })
        : null;
    return (
        <PlayerStairsContainer>
            {playersData?.map((playerData, index) => {
                return (
                    <Player
                        className={ isCheckingAnswersStatus ? 'alternateCorrect' : ''}
                        key={playerData.id}
                        style={{ marginTop: index * PLAYERS_STAIR_MARGIN }}
                        data={{...playerData, isAnswerCorrect: !isCheckingAnswersStatus || playerData.answer === correctAnswer}}
                    />
                );
            })}
        </PlayerStairsContainer>
    );
};
