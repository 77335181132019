// Core
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import { AnswersScreen } from "src/components/participant/answersScreen";
import { CategorySelection } from "src/components/participant/categorySelection";
import { InfoScreen } from "src/components/participant/infoScreen";
import { LoadingScreen } from "src/components/participant/loading";
import { WagerScreen } from "src/components/participant/wagerScreen";

// Elements
import { BgDollars } from "src/elements/bgDolars";
import { Text } from "src/elements/text";

// Actions
import { setNoInternetAction, setRequesterAction, setTechnicalErrorAction } from "src/Logic/actions/participants/actions";

// Types
import { ParticipantsActionTypes } from "src/Logic/actions/participants/types";

// API
import { sendAnswer } from "src/Logic/API/Participant/answer";
import { chooseCategory } from "src/Logic/API/Participant/chooseCategory";
import { getGameSate } from "src/Logic/API/Participant/getGameState";
import { makeWager } from "src/Logic/API/Participant/makeWager";

// Hooks
import { useParticipantLogic } from "src/Logic/Hooks/useParticipantLogic";
import { initialState, participantReducer, StateType } from "src/Logic/reducers/participantReducer";

// Requester
import { ParticipantRequester } from "src/Logic/Requesters/ParticipantRequester";

// Cookies
import { parseCookies } from 'nookies';

// Elements
import { Header } from "src/elements/header";
import { Button } from "src/elements/buttons";

// Styles
import { Page } from "./styles";

// Helpers 
import { ToDisplayNumber } from "src/Logic/Helpers/number-helpers";

export type ParticipantContextType = {
    participantState: StateType,
    dispatch: React.Dispatch<ParticipantsActionTypes>
}

export const ParticipantContext = React.createContext<ParticipantContextType>({participantState: initialState, dispatch: () => null});
    
export const PlayersPage = () => {

    const requester = useMemo<ParticipantRequester>(() => new ParticipantRequester(), []);
    const [ participantState, dispatch ] = useReducer(participantReducer, initialState); 
    const [ token, setToken ] = useState<string>();
    const { checkComponentsToDisplay } = useParticipantLogic();
    const navigate = useNavigate();
    const needToDisplay = checkComponentsToDisplay( participantState.state );

    useEffect(() => {
        dispatch(setRequesterAction( requester ));
    }, [ requester ]);

    useEffect(() => {
        
        const cookies = parseCookies();
        const storedToken = cookies.token;

        if( !storedToken )
            navigate('/sign-in');
        else 
            setToken( storedToken );

        if( !participantState.noInternet && !participantState.technicalError)
        {
            let timeout: NodeJS.Timeout | undefined = undefined;
            if( storedToken ) {
                timeout = setTimeout( async function run () {
                    try{
                        if( storedToken ) 
                        {
                            console.log('Update status...');
                            await getGameSate( storedToken, requester, dispatch, navigate )
                            console.log('Status updated.');
                        } 
                    }
                    finally {
                        if (timeout)
                            clearTimeout(timeout);
                        timeout = setTimeout(run, 1000);
                    }
                }, 1000);
            }
            else 
            {
                navigate('/');
            }

            return () => { if( timeout !== undefined) clearTimeout( timeout )};
    }

    }, [ participantState.technicalError, requester, participantState.noInternet ]);

    const handleCategoryChoose = ( category: string) => {
        if( token)
            chooseCategory( category, token, requester, dispatch, navigate );
    }

    const handleMakeWager = ( value: number) => {
        if( token )
            makeWager( value, token, requester, dispatch, navigate );
    }

    const handleAnswer = ( answer: string ) => {
        if( token )
            sendAnswer( answer, token,  requester, dispatch, navigate );
    }

    const gameScreensRender = () => {

        if( participantState.state && !participantState.technicalError )
            return (
                <>
                    { needToDisplay.playersLoading ? 
                            <LoadingScreen 
                                progress = { participantState.state.progress }
                                label = 'Other players are joining room...'/> : null }

                        { needToDisplay.categorySelection ? 
                            <CategorySelection 
                                categories = { participantState.state.possibleCategories } 
                                onChoose = { handleCategoryChoose } 
                                text = {`Please select a category for Round ${ participantState.state?.roundNumber }`} /> : null }

                        { needToDisplay.categorySelected ? 
                            <InfoScreen>
                                <Header />
                                <Text className = "categorySelected">{`Hooray! Round ${ participantState.state.roundNumber } category has been selected!`}</Text>
                            </InfoScreen> : null}

                        { needToDisplay.waitingForSelection ?   
                            <LoadingScreen 
                                progress = { participantState.state.progress }
                                label = "Other player is selecting category..." /> : null }

                        { needToDisplay.makingWager ? 
                            <WagerScreen makeWager = { handleMakeWager } balance = {  participantState.state.balance }/> : null }

                        { needToDisplay.waitingOtherWagers ? 
                            <LoadingScreen 
                                label = "Other players are entering wagers..." 
                                progress = { participantState.state.progress  } /> : null }

                        { needToDisplay.answer.show ?  
                            <AnswersScreen 
                                answered = { needToDisplay.answer.state }
                                question = { participantState.state.question }
                                roundNumber = { participantState.state?.roundNumber  }
                                answers = {  participantState.state.possibleAnswers } 
                                category = {  participantState.state.roundCategory }  
                                onAnswer = { handleAnswer }/> : null }
                        { needToDisplay.elimination ?   
                            <InfoScreen>
                                <Header />
                                <Text className = "elimination">{ participantState.state.eliminatedParticipantNames && 
                                participantState.state.eliminatedParticipantNames.length > 1 ? `${participantState.state.eliminatedParticipantNames.length} players have been eliminated.` : 
                                'One player has been eliminated.'}</Text>
                            </InfoScreen> : null }
                        { needToDisplay.youEliminated ? 
                            <InfoScreen>
                                <Header />
                                <Text className = "elimination">{ `Sorry, with a wager of $${ 
                                     ToDisplayNumber(participantState.state.yourWager)  }, you’ve been eliminated.` }</Text>
                            </InfoScreen> : null } 
                        { needToDisplay.noOneEliminated ? 
                            <InfoScreen>
                                <Header/>
                                <Text className = "elimination" >No one is eliminated.</Text>
                            </InfoScreen> : null }
                        { needToDisplay.winner ? 
                            <InfoScreen>
                                    <Header/>
                                    <Text className = "winner elimination" >Your Final Bank Balance:</Text>
                                    <Text className = "winnerBalance" >{` $${ToDisplayNumber(participantState.state.balance) }`}</Text>
                            </InfoScreen> : null }
                </>
            )
        if( participantState.technicalError )
        return (  
            <InfoScreen>
                <Header/>
                <Text className = "technicalErrorMessage" >{`Technical error ${ participantState.technicalError }`}</Text>
                <Button onClick = { () => { dispatch(setTechnicalErrorAction( null ))}}>OK</Button>
            </InfoScreen>
        );
        if( participantState.noInternet)
            return ( 
                <InfoScreen>
                    <Header/>
                    <Text className = "technicalErrorMessage" >No internet connection.</Text>
                    <Text>You are offline. Check your connection.</Text>
                    <Button onClick = { () => { dispatch(setNoInternetAction( false )) }}>Retry</Button>
                </InfoScreen> )
        
        return ( <></> );
    }
    
    return( 
        <Page>
             { gameScreensRender() }
            <BgDollars />
        </Page>
    )
}