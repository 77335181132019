import { Text } from "src/elements/text";
import { StudioInfoScreen } from "../StudioInfoScreen";

type Props = {
    participantCount: number,
}

export const StudioStartGameScreen = ({ participantCount }: Props) => {
    return (
        <StudioInfoScreen>
            <Text className="primary mrBottomLarge">We’ve got {participantCount} players.</Text>
            <Text className="primary" >Let’s begin!</Text>
        </StudioInfoScreen>
    )
}