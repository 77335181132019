import styled from "styled-components"

const Wrapper = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: #000;
        text-transform: capitalize;
        display: block;
        margin-bottom: 8px;
    }
`;

const Bar = styled.div`
    width: 100%;
    border: 1px solid #01A585;
    box-sizing: border-box;
    border-radius: 4px;
    height: 24px;
    transition: all 0.1s linear;

    .progressBar {
        background: linear-gradient(270deg, #00BF58 0%, #00A8BF 100%);
        border-radius: 4px;
        height: 100%;
    }

`;

type Props = {
    style?: React.CSSProperties | undefined,
    className?: string | undefined, 
    progress?: number;
    label?: string;
    labelClass?: string,
}

export const ProgressBar = ({ style, className, progress, label, labelClass }: Props ) => {

    let innerProgress = progress;
    if( progress === undefined || progress < 0)
        innerProgress = 0;
    else if( progress > 100)
        innerProgress = 100;

    return (
        <Wrapper>
            { label !== undefined ? <span className = {labelClass}>{ label }</span> : null }
            <Bar style = { style } className = { className }>
                <div style = {{width: `${ innerProgress }%`}} className = "progressBar" />
            </Bar>
        </Wrapper>
    )
}