import { OperatorRequester } from "src/Logic/Requesters/OperatorRequester";
import { setCookie } from 'nookies'
import { ErrorCodes } from "src/Logic/Models/General/technicalErrors";
import { NavigateFunction } from "react-router-dom";

export const enterToTheGame = async (
    roomCode: number | string,
    requester: OperatorRequester,
    setTechnicalError: React.Dispatch<React.SetStateAction<ErrorCodes | number | null>>,
    setIsNoInternet: React.Dispatch<React.SetStateAction<boolean>>,
    navigate: NavigateFunction,
    ) => {
    if( !navigator.onLine )
    {
        setIsNoInternet( true );
    }
    else
    try {
     	await requester.EnterToTheGame( roomCode ).then( async response => { 
            if( response ) 
            {
                if( response.status === 200)
                {
                    setCookie(null, 'roomCode', roomCode.toString() );
                    navigate('/game');
                }
                else if (response.status === 401 )
                {
                    navigate('/');
                }
                else if (response.status === 400 )
                {
                    const body = await response.json();
                    if( body.errorCode )
                    {
                        setTechnicalError( body.errorCode );
                    }
                }
                else
                    setTechnicalError( response.status );
            }
        });
    }
    catch (error) {
        console.log(error);
    }
}
