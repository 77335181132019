import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';

const InputWrapper = styled.div`
    width: 313px;
    font-family: 'Gotham A', 'Gotham B';
    z-index: 1;
    @media (max-width: 426px) {
        width: 100%;
    }

    span {
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: #000;
        text-transform: capitalize;
        margin-bottom: 8px;
        display: block;

        &.inputErrorSpan {
            position: absolute;
            color: ${props => props.theme.colors.red.main};
            font-size: 12px;
            line-height: 16px;
            text-transform: none;
        }
    }

    input {
        width: 100%;
        height: 40px;
        font-weight: bold;
        color: #000;
        border: 1px solid ${props => props.theme.colors.gray.input};
        box-sizing: border-box;
        border-radius: 4px;
        padding-left: 11px;
        letter-spacing: 0.01em;
        font-size: 14px;
        background-color: #fff;

        &.error {
            border-color: ${props => props.theme.colors.red.main};
        }

        :focus-visible {
            outline: none;
        }

        ::placeholder {
            font-size: 14px;
            font-family: 'Gotham A', 'Gotham B';
            color: ${props => props.theme.colors.gray.main};
            letter-spacing: 0.01em;
            text-transform: capitalize;
        }
    }
`;

type Props = {
    placeholder?: string | undefined;
    label?: string | undefined;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    className?: string | undefined;
    error?: string | undefined | null;
    maxLength?: number;
    value?: string;
};

export const Input = ({
    label,
    placeholder,
    onChange,
    className,
    error,
    maxLength,
    value,
}: Props) => {
    return (
        <InputWrapper className={className}>
            {label !== undefined ? <span>{label}</span> : null}

            <NumericFormat
                className={error ? 'error' : ''}
                placeholder={placeholder}
                onChange={onChange}
                thousandSeparator={true}
                allowNegative={false}
                isAllowed={value =>
                    value.value.indexOf('.') === -1 &&
                    (value.floatValue === undefined || value.floatValue % 1 === 0)
                }
                value={value}
                maxLength={maxLength}
            />

            {error !== undefined && error !== null ? (
                <span className="inputErrorSpan">{error}</span>
            ) : null}
        </InputWrapper>
    );
};
