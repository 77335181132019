// Core
import { useEffect, useMemo, useState } from "react";

// Components
import { AdminTechnicalError } from "src/components/admin/adminTechnicalError";
import { AdminNoInternet } from "src/components/admin/noInternet";
import { GameControlScreen } from "src/components/admin/GameControlScreen";

// Elements
import { Logo } from "src/elements/logo";

// Requester
import { OperatorRequester } from "src/Logic/Requesters/OperatorRequester";

// Types
import { OperatorGameState } from "src/Logic/Models/Response/operator/gameStatus";

// Cookies
import { parseCookies } from 'nookies'

// API
import { getGameState } from "src/Logic/API/operator/getGameState";
import { useAdminPageLogic } from "src/Logic/Hooks/useAdminPageLogic";
import { changeGameStatus } from "src/Logic/API/operator/changeGameStatus";

// Components
import { Page } from "./styles";

// Enums
import { ErrorCodes } from "src/Logic/Models/General/technicalErrors";

// React router
import { useNavigate } from "react-router-dom";
import { deleteParticipant } from "src/Logic/API/operator/deleteParticipant";
import { GameStatuses } from "src/Logic/Models/General/gameStatuses";

export const AdminPage = () => {

    const requester = useMemo<OperatorRequester>(() => new OperatorRequester(), []);
    
    const [ adminPageState, setAdminPageState ] = useState<OperatorGameState>();
    const [ technicalError, setTechnicalError ] = useState<ErrorCodes | number | null>( null );
    const [ roomCode, setRoomCode ] = useState<string>();
    const [ isNoInternet, setIsNoInternet ] = useState( false );
    const { getControlPageSettings } = useAdminPageLogic();
    const navigate = useNavigate();

    const controlPageSettings = getControlPageSettings( adminPageState );

    useEffect(() => {
        const cookies = parseCookies();
        const storedRoomCode = cookies.roomCode;
        console.log('stored', storedRoomCode)
        if( storedRoomCode )
        {
            setRoomCode(storedRoomCode);
        }
        else 
            navigate('/sign-in')
    }, []);

    useEffect(() => { 
        if(!isNoInternet && !technicalError )
        {
            let timeout = setTimeout(async function run() { 
                try{
                    if( roomCode !== undefined ) {
                        console.log('Update status...');
                        await getGameState(roomCode, requester, setAdminPageState, setTechnicalError, setIsNoInternet, navigate ) 
                        console.log('Status updated.');
                    }
                }
                finally{
                    clearTimeout(timeout);
                    timeout = setTimeout(run, 1000);
                }
            }, 1000 );

            return () => { if( timeout ) clearTimeout( timeout )};
        }

    }, [ requester, roomCode, technicalError, isNoInternet ]);

    const handleGameStatusChange = () => {
        if( isNoInternet )
            setIsNoInternet( false )
        if (roomCode)
            changeGameStatus( controlPageSettings.button.statusForChange , roomCode, requester, setTechnicalError, setIsNoInternet, navigate )
    }

    const handleDeleteParticipant = (name: string) => {
        if(roomCode)
            deleteParticipant(name, roomCode, requester, setTechnicalError, setIsNoInternet, navigate);
    }

    return (
        <Page>
            <Logo className = 'descktopLogo'/>
            { adminPageState && !technicalError && !isNoInternet ? 
            <GameControlScreen 
                gamelink = { adminPageState.participantAppUrl }
                eliminatedPlayers = {adminPageState.eliminatedParticipantNames || [] }
                roomCode = { roomCode }
                gameName = { adminPageState.gameName }
                settings = { controlPageSettings }
                question = { adminPageState.question }
                roundNumber = { adminPageState.roundNumber }
                label = { adminPageState.roundCategory }
                winners = { adminPageState.winnerNames }
                onGameStatusChange = { handleGameStatusChange } 
                onDelete = { handleDeleteParticipant }
                canDelete = { adminPageState.gameStatus === GameStatuses.enteringRoom }
                correctAnswer = {adminPageState.correctAnswer ? `${adminPageState.correctAnswer.letter}. ${adminPageState.correctAnswer.text}` : undefined}
                /> : null }
            { technicalError ? <AdminTechnicalError error = { technicalError } onOk = { () => setTechnicalError( null ) } /> : null } 
            { isNoInternet ? <AdminNoInternet onRetry={ () => { setIsNoInternet( false )} }/> : null }
        </Page>
    )
}