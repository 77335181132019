import { NavigateFunction } from "react-router-dom";
import { ErrorCodes } from "src/Logic/Models/General/technicalErrors";
import { AudienceGameState } from "src/Logic/Models/Response/audience/gameStatus";
import { AudienceRequester } from "src/Logic/Requesters/AudienceRequester";

export const getGameState = async (
    roomCode: string | number,
    requester: AudienceRequester,
    setAudiencePageState: React.Dispatch<React.SetStateAction<AudienceGameState | undefined>>,
    setTechnicalError: React.Dispatch<React.SetStateAction<ErrorCodes | number | null>>,
    setIsNoInternet: React.Dispatch<React.SetStateAction<boolean>>,
    navigate: NavigateFunction,
    ) => {
    if( !navigator.onLine )
    {
        setIsNoInternet( true );
    }
    else
    try {
     	await requester.GetGameState( roomCode ).then( async response => { 
            if( response )
            {
                if( response.status === 200 )
                {
                    const data:AudienceGameState = await response.json();
                    setAudiencePageState(data);
                }
                else if( response.status === 401 )
                    navigate('/sign-in');
                else if ( response.status === 400 )
                {
                    const body = await response.json();
                    if( body.errorCode )
                        setTechnicalError( body.errorCode );
                }
                else
                    setTechnicalError( response.status )
            }
        });
    }
    catch (error) {
        console.log(error);
    }
}
