import { PlayerEnterParams } from "src/Logic/Models/Requests/PlayerEnterParams";
import { ParticipantRequester } from "src/Logic/Requesters/ParticipantRequester";
import { setCookie } from 'nookies'
import { ErrorCodes } from "src/Logic/Models/General/technicalErrors";
import { NavigateFunction } from "react-router-dom";

export const enterToTheGame = async (
    params: PlayerEnterParams,
    requester:ParticipantRequester,
    setIsroomCodeWrong: React.Dispatch<React.SetStateAction<boolean>>,
    setTechnicalError: React.Dispatch<React.SetStateAction<ErrorCodes | number | null>>,
    setNoIntenet: React.Dispatch<React.SetStateAction<boolean>>,
    navigate: NavigateFunction,
    ) => {
    if( !navigator.onLine )
    {
        setNoIntenet( true );
    }
    else
    try {
     	await requester.EnterToTheGame( params ).then( async response => { 
            console.log( response )
            if( response)
            {
                console.log( 'inside response')
                if( response.status === 401 )
                   setIsroomCodeWrong( true );
                else 
                {
                    const body = await response.json();
                    if( body.errorCode )
                        setTechnicalError( body.errorCode as ErrorCodes);
                    else if( body.token ) 
                    {
                        setCookie(null, 'token', body.token);
                        setIsroomCodeWrong( false );
                        navigate('/game')
                    }
                    else
                    {
                        setTechnicalError( response.status );
                    }
                }
            }
        });
    }
    catch (error) {
        console.log(error);
    }
}
