import { setCookie } from 'nookies'
import { ErrorCodes } from "src/Logic/Models/General/technicalErrors";
import { NavigateFunction } from "react-router-dom";
import { AudienceRequester } from "src/Logic/Requesters/AudienceRequester";

export const enterToTheGame = async ({
    navigate,
    requester,
    roomCode,
    setIsNoInternet,
    setIsRoomNotFound,
    setTechnicalError
}:{
    roomCode: number | string,
    requester: AudienceRequester,
    setTechnicalError: React.Dispatch<React.SetStateAction<ErrorCodes | number | null>>,
    setIsRoomNotFound: React.Dispatch<React.SetStateAction<boolean>>,
    setIsNoInternet: React.Dispatch<React.SetStateAction<boolean>>,
    navigate: NavigateFunction,
}) => {
    if( !navigator.onLine ) {
        setIsNoInternet( true );
    }
    else
    try {
     	await requester.EnterToTheGame( roomCode ).then( async response => { 
            if( response ) {
                const statusCode = response.status;
                if( statusCode === 200) {
                    setCookie(null, 'roomCode', roomCode.toString() );
                    navigate('/game');
                }
                else if (statusCode === 401 )
                    navigate('/');
                else if( statusCode === 404 )
                    setIsRoomNotFound(true);
                else if (statusCode === 400 ) {
                    const body = await response.json();
                    if( body.errorCode )
                    {
                        setTechnicalError( body.errorCode );
                    }
                }
                else
                    setTechnicalError( response.status );
            }
        });
    }
    catch (error) {
        console.log(error);
    }
}
