import { PlayerDataType } from 'src/components/admin/playersRow/helpers/makePlayersData';
import styled from 'styled-components';
import DeleteIcon from '../../assets/images/delete.svg';
import { Text } from '../text';

const Wrapper = styled.div`
    display: flex;
    /* width: 110px; */
    height: 128px;
    flex-direction: column;
    margin: 0 32px;
    position: relative;
    align-items: center;

    .playerIcon {
        width: 75px;
    }

    &.disabled {
        opacity: 0.3;
    }

    .name {
        font-size: 20px;
    }

    .wager {
        font-size: 26px;
    }

    .answer {
        font-size: 30px;

        &.wrong {
            color: red;
        }
    }

    .deleteIcon {
        position: absolute;
        top: 0;
        right: 11px;
        cursor: pointer;
    }

    .balance {
        font-size: 16px;
        line-height: 20px;
        font-weight: normal;
        text-align: center;
        text-transform: capitalize;
        margin: 6px 0px;
    }
`;

type Props = {
    data: PlayerDataType;
    onDelete?: (name: string) => void;
    canDelete?: boolean;
    style?: React.CSSProperties | undefined;
    className?: string | undefined;
};

export const Player = ({
    data: { answer, wager, name, isAnswerCorrect, icon, disabled, balance },
    onDelete,
    canDelete,
    style,
    className,
}: Props) => {
    return (
        <Wrapper style={style} className={`${className ? className : ''}${disabled ? ' disabled' : ''}`}>
            <img className="playerIcon" src={icon} alt="" />
            {canDelete ? (
                <img
                    onClick={() => {
                        onDelete?.(name);
                    }}
                    className="deleteIcon"
                    src={DeleteIcon}
                    alt="Delete"
                />
            ) : null}
            <Text className="name">{name}</Text>
            {balance !== undefined ? <Text className="balance">{balance}</Text> : null}
            <Text className="wager">{wager}</Text>
            <Text className={`answer${isAnswerCorrect ? '' : ' wrong'}`}>{answer}</Text>
        </Wrapper>
    );
};
