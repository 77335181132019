import { Button } from 'src/elements/buttons';
import { Label } from 'src/elements/label';
import { ProgressBar } from 'src/elements/progressBar';
import { Text } from 'src/elements/text';
import { ControlPageSettingsType } from 'src/Logic/Hooks/useAdminPageLogic';
import styled from 'styled-components';
import { ConnectionInfo } from '../connectionInfo';
import { PlayersRow } from '../playersRow';

const Div = styled.div`
    width: 741px;
    display: flex;
    flex-direction: column;
    align-items: center;

    header {
        display: flex;
        width: 295px;
        min-width: fit-content;
        height: 60px;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        .round {
            font-size: 30px;
            margin-right: ${props => props.theme.spacing.small};
        }
        .category {
            height: 40px;
            font-size: 26px;
        }

        &.withTopMarging {
            margin-top: 17px;
        }
    }
    .progress {
        width: 721px;
    }

    .progressInfo {
        text-align: center;
        font-family: 'Gotham A', 'Gotham B';
    }

    .question {
        margin-top: ${props => props.theme.spacing.main};
        margin-bottom: 46px;
        height: 60px;
        display: flex;
        align-items: center;
        width: 80vw;

        .text {
            width: 100%;
            font-size: 30px;
        }
    }

    .selectingCategoryText {
        font-size: 30px;
        margin-top: 56px;
    }

    .additionalInfo {
        height: 20px;
        font-size: 18px;
        margin: 55px 0;
    }

    .secondRow {
        margin-bottom: 0;
    }

    .controlScreenButtonContainer {
        min-height: 50px;
    }
    .noWinners {
        color: ${props => props.theme.colors.red.main};
    }
`;

type Props = {
    onGameStatusChange: () => void;
    roundNumber: number;
    label: string | undefined;
    question: string | undefined;
    eliminatedPlayers: string[];
    settings: ControlPageSettingsType;
    roomCode: string | undefined;
    gameName: string;
    gamelink: string;
    winners: string[] | undefined;
    onDelete: (name: string) => void;
    canDelete: boolean;
    correctAnswer?: string;
};
export const GameControlScreen = ({
    onGameStatusChange,
    roundNumber,
    label,
    question,
    eliminatedPlayers,
    settings,
    roomCode,
    gameName,
    gamelink,
    winners,
    onDelete,
    canDelete,
    correctAnswer,
}: Props) => {
    const handleButtonClick = () => {
        onGameStatusChange();
    };

    console.log(settings);
    return (
        <Div>
            <header className={settings.categorySelecting ? 'withTopMarging' : ''}>
                <Text className="round">{settings.round ? `ROUND ${roundNumber}` : ''}</Text>
                {settings.label ? <Label className="category">{label}</Label> : null}
            </header>
            {settings.progress.show ? (
                <ProgressBar
                    className="progress"
                    labelClass="progressInfo"
                    label={settings.progress.message}
                    progress={settings.progress.percentage}
                />
            ) : null}
            {settings.categorySelecting || settings.displayWinners ? (
                <Text
                    className={`selectingCategoryText${
                        settings.displayWinners && winners?.length === 0 ? ' noWinners' : ''
                    }`}
                >
                    {settings.categorySelecting
                        ? 'Selecting category...'
                        : winners && winners.length > 0
                        ? `Winner${winners.length > 1 ? 's' : ''}:`
                        : 'No Winner'}
                </Text>
            ) : null}
            {/* { settings.finalBid ? <FinalBidLogo /> : null } */}
            {settings.gameInfo ? (
                <ConnectionInfo gameCode={roomCode} gameLink={gamelink} gameName={gameName} />
            ) : null}
            <div className="question">
                {settings.question ? (
                    <Text>{question} </Text>
                ) : settings.checkingAnswers ? (
                    <Text>{correctAnswer} </Text>
                ) : null}
            </div>
            {!settings.gameInfo ? (
                <>
                    <PlayersRow
                        canDelete={canDelete}
                        onDelete={onDelete}
                        participants={settings.firstRowParticipants}
                        displayWinners={settings.displayWinners}
                    />
                    <PlayersRow
                        canDelete={canDelete}
                        onDelete={onDelete}
                        participants={settings.secondRowParticipants}
                        displayWinners={settings.displayWinners}
                        className="secondRow"
                    />
                </>
            ) : null}
            <Text className="additionalInfo">
                {settings.eliminatedPlayer
                    ? `Player${eliminatedPlayers.length > 1 ? 's' : ''} ${eliminatedPlayers.join(
                          ', ',
                      )} ${eliminatedPlayers.length > 1 ? 'are' : 'is'} eliminated.`
                    : ''}
            </Text>
            <div className="controlScreenButtonContainer">
                {settings.button.display ? (
                    <Button
                        onClick={handleButtonClick}
                        disabled={settings.button.disabled}
                        className="button"
                    >
                        {settings.button.text}
                    </Button>
                ) : null}
            </div>
        </Div>
    );
};
