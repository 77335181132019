import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    height: 100%;

    header {
        display: flex;
        /* width: 295px; */
        min-width: fit-content;
        height: 60px;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        .round {
            font-size: 30px;
            margin-right: ${ props => props.theme.spacing.small };
        }
        .category {
            height: 40px;
            font-size: 26px;
        }

        &.withTopMarging {
            margin-top: 17px;
        }
    }

    .eliminationMessage {
        font-size: 26px;
        margin-bottom: 105px;
        margin-top: -105px;
    }
`;