// Routes
import { RoutesSelector } from './routes';

function App() {

    return (
        <RoutesSelector />
    );
}

export default App;
