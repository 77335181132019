import { Button } from "src/elements/buttons";
import { Header } from "src/elements/header";
import { Text } from "src/elements/text"
import styled from "styled-components";


const Div = styled.div`
    z-index: 1;
    margin-top: ${ props => props.theme.spacing.main};
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */

    .mrBSmall {
        margin-bottom: ${ props => props.theme.spacing.small };
    }
`;


type Props = {
    categories: string[] | undefined,
    text: string,
    onChoose: (value: string ) => void;
}

export const CategorySelection = ({ text, categories, onChoose }: Props) => {
    return (
        <>
            <Header />
            <Div>
                <Text className = 'mrBSmall'>{ text }</Text>
                { categories ? categories.map(( category ) => { return  <Button 
                                                                variant = "gradient" 
                                                                className = 'mrBSmall' 
                                                                onClick = {() => { onChoose( category) }}>{category}</Button> }) 
                :
                null
                }
                
            </Div>
        </>
    );
}