import { NavigateFunction } from "react-router-dom";
import { GameStatusesForChange } from "src/Logic/Models/General/gameStatuses";
import { ErrorCodes } from "src/Logic/Models/General/technicalErrors";
import { OperatorRequester } from "src/Logic/Requesters/OperatorRequester";

export const deleteParticipant = async (
    name: string,
    roomCode: string,
    requester: OperatorRequester,
    setTechnicalError: React.Dispatch<React.SetStateAction<ErrorCodes | number | null>>,
    setIsNoInternet: React.Dispatch<React.SetStateAction<boolean>>,
    navigate: NavigateFunction,
    ) => {
    if( !navigator.onLine )
    {
        setIsNoInternet( true )
    }
    else
    try {
     	await requester.DeleteParticipant( name, roomCode ).then( async response => { 
            if( response ) 
            {
                if( response.status === 401 )
                    navigate('/sign-in');
                else if( response.status === 400 )
                {
                    const body = await response.json();
                    if( body.errorCode )
                    setTechnicalError( body.errorCode )
                }
                else if(response.status !== 200 )  
                    setTechnicalError( response.status );
            }
        });
    }
    catch (error) {
        console.log(error);
    }
}
