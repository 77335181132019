import { BaseRequester } from "./BaseRequester";

export class AudienceRequester extends BaseRequester {

    public async EnterToTheGame(roomCode: number | string): Promise<Response | undefined> {
        return await this.MakePutRequest(`/api/AudienceMonitor/enter`, { roomCode });
    }

    public async GetGameState(roomCode: number | string ): Promise<Response | undefined> {
        return await this.MakeGetRequest(`/api/AudienceMonitor/state`, null, new Headers({ roomCode: roomCode.toString() }));
    }

}