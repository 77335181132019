import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import App from 'src/app';
import reportWebVitals from 'src/reportWebVitals';
import './assets/styles/app.scss';

import { ThemeProvider } from 'styled-components';
import { defaultTheme } from 'src/assets/theme'

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider theme = {defaultTheme}>
                    <App />
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
