type AnswerType = {
    letter: string, 
    text: string
}

 type ColumnsType = {
    firstColumn?: AnswerType[],
    secondColumn?: AnswerType[],
    thirdColumn?: AnswerType[],
}

export const useStudioAnswersLogic = () => {

    const MAX_FONT_SIZE = 72;
    const MIN_CONST_SIZE = 32;
    const TARGET_WINDOW_HEIGHT = 316;

    const splitAnswers = ( answers: AnswerType[] | undefined ) => {

        if( answers )
        {
            const columns:ColumnsType = {};
            if ( answers.length < 5 )
            {
                columns.firstColumn = answers.slice( 0, 2);
                columns.secondColumn = answers.slice( 2, 5);  
                // columns.thirdColumn = answers.slice( 2, 3); 
            }
            else if( answers.length < 7 )
            {
                columns.firstColumn = answers.slice( 0, 3);
                columns.secondColumn = answers.slice( 3, 6);  
                // columns.thirdColumn = answers.slice( 4, 6);
            }
            else if( answers.length <= 10)
            {
                columns.firstColumn = answers.slice( 0, 3);
                columns.secondColumn = answers.slice( 3, 6);  
                columns.thirdColumn = answers.slice( 6, 10);
            }
            else if( answers.length === 11)
            {
                columns.firstColumn = answers.slice( 0, 4);
                columns.secondColumn = answers.slice( 4, 7);  
                columns.thirdColumn = answers.slice( 7, 11);
            }
            else if( answers.length === 12)
            {
                columns.firstColumn = answers.slice( 0, 4);
                columns.secondColumn = answers.slice( 4, 8);  
                columns.thirdColumn = answers.slice( 8, 12);
            }
            return columns;
        }
    }

    const calculateAnswerFontSize = ( 
        height: number, 
        fontSize: number,
        setFontSize: React.Dispatch<React.SetStateAction<number>>, 
        // setDisplayAnswers: React.Dispatch<React.SetStateAction<boolean>>, 
        ) => {

            console.log( height )
            if( height > TARGET_WINDOW_HEIGHT && fontSize > MIN_CONST_SIZE)
            {
                setFontSize(--fontSize)
              
                console.log( fontSize);
            }
            // setDisplayAnswers( true );
            return fontSize;
        }

    return {
        splitAnswers,
        calculateAnswerFontSize,
        MAX_FONT_SIZE,
        MIN_CONST_SIZE,
    } 
}