import { Logo } from "src/elements/logo";
import { Text } from "src/elements/text";
import styled from "styled-components"

const Div = styled.div`
    .welComeLogo {
        width: 1197px;
        height: 358px; 
        margin: 44px 0;
    }

    .welcomeText {
        color: #fff;
        font-size: 80px;
    }

    .url {
        font-weight: normal;
        font-size: 50px;
        line-height: 80px;
    }
`;

type Props = {
    roomCode?: number | string,
}

export const StudioWelComeScreen = ({ roomCode }: Props ) => {

    return (
        <Div>
            <Text className = "welcomeText">Welcome to</Text>
            <Logo className = "welComeLogo"/>
            <Text className = "welcomeText url">Visit instantmil.com</Text>
            <Text className = "welcomeText"> Your room code: { roomCode }</Text>
        </Div>
    )

}