
export enum GameStatuses {
    notStarted = 'notStarted',
    enteringRoom = 'enteringRoom',
    selectingCategory = 'selectingCategory',
    makingWagers = 'makingWagers',
    displayEliminated = 'displayEliminated',
    answering = 'answering',
    checkingAnswers = 'checkingAnswers',
    displayWinner = 'displayWinner',
}

export enum GameStatusesForChange {
    newRound = 'newRound', 
    displayEliminated = 'displayEliminated', 
    answering = 'answering', 
    checkingAnswers = 'checkingAnswers',
}