import React, { useLayoutEffect, useRef, useState } from 'react';
import { Label } from 'src/elements/label';
import { Text } from 'src/elements/text';
import { Winner } from 'src/elements/winner';
import { useStudioAnswersLogic } from 'src/Logic/Hooks/useStudioAnswerLogic';
import { StudioInfoScreen } from '../StudioInfoScreen';

type Props = {
    round?: string | number;
    question?: string;
    category?: string;
    answer?: string;
    possibleAnswers?: { letter: string; text: string }[];
    correctAnswersCount?: number;
    winnerNames?: string[];
    winnersCount?: number;
    winnerBalance?: number | undefined;
    correctlyAnsweredParticipantNames?: string[] | undefined;
};

export const OriginalStudioRoundScreen = ({
    round,
    question,
    category,
    answer,
    correctAnswersCount,
    winnerNames,
    winnersCount,
    possibleAnswers,
    winnerBalance,
    correctlyAnsweredParticipantNames,
}: Props) => {
    const { splitAnswers, calculateAnswerFontSize, MAX_FONT_SIZE } = useStudioAnswersLogic();
    const [fontSize, setFontSize] = useState(MAX_FONT_SIZE);

    const firstContainerRef = useRef<HTMLDivElement>(null);

    const splitedAnswers = splitAnswers(possibleAnswers);

    const correctAnswersCountText = `${correctAnswersCount ?? 0} player${
        correctAnswersCount === 1 ? '' : 's'
    } answered correctly.`;
    const correctlyAnsweredParticipantsText = `They are: ${correctlyAnsweredParticipantNames?.join(
        ', ',
    )}`;

    useLayoutEffect(() => {
        console.log('reset');
        setFontSize(MAX_FONT_SIZE);
    }, [possibleAnswers]);

    useLayoutEffect(() => {
        console.log('fontSize calculating ');
        if (firstContainerRef.current)
            setFontSize(
                calculateAnswerFontSize(
                    firstContainerRef.current.offsetHeight,
                    fontSize,
                    setFontSize,
                ),
            );
    }, [firstContainerRef, fontSize]);

    return (
        <StudioInfoScreen className={possibleAnswers ? 'withMargin' : ''}>
            <Text className="primary">{`ROUND ${round}`}</Text>
            <Label className="descktopLabel mrBottomLarge">{category}</Label>
            <Text className="small question mrBottomMain ">{question}</Text>
            {!splitedAnswers ? (
                <Text
                    className={`primary correctAnswer${
                        winnerNames ? ' marginBetweenWinners' : ' mrBottomExtraLarge'
                    }`}
                >
                    {answer}
                </Text>
            ) : null}

            {correctAnswersCount !== undefined ? (
                <Text className="correctAnswers wellVisible" dataText={correctAnswersCountText}>
                    {correctAnswersCountText}
                </Text>
            ) : (
                <></>
            )}
            {correctlyAnsweredParticipantNames !== undefined && correctAnswersCount ? (
                <Text className="wellVisible correctlyAnsweredParticipants">
                    {correctlyAnsweredParticipantsText}
                </Text>
            ) : (
                <></>
            )}
            {winnerNames ? (
                <Winner
                    winnersCount={winnersCount}
                    names={winnerNames}
                    winnerBalance={winnerBalance}
                />
            ) : (
                <></>
            )}
            {splitedAnswers && answer === undefined && winnersCount === undefined ? (
                <div className={`answers`}>
                    {splitedAnswers.firstColumn && splitedAnswers.firstColumn.length > 0 ? (
                        <div className="answersColumn" ref={firstContainerRef}>
                            {splitedAnswers.firstColumn.map(answer => {
                                return (
                                    <div className="answerContainer">
                                        <div>
                                            <Text
                                                style={{ fontSize: fontSize }}
                                                className="primary answer"
                                            >
                                                {`${answer.letter}.`}&nbsp;
                                            </Text>
                                        </div>
                                        <div>
                                            <Text
                                                style={{ fontSize: fontSize }}
                                                className="primary answer"
                                            >{`${answer.text}`}</Text>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                    {splitedAnswers.secondColumn && splitedAnswers.secondColumn.length > 0 ? (
                        <div className="answersColumn">
                            {splitedAnswers.secondColumn.map(answer => {
                                return (
                                    <div className="answerContainer">
                                        <div>
                                            <Text
                                                style={{ fontSize: fontSize }}
                                                className="primary answer"
                                            >
                                                {`${answer.letter}.`}&nbsp;
                                            </Text>
                                        </div>
                                        <div>
                                            <Text
                                                style={{ fontSize: fontSize }}
                                                className="primary answer"
                                            >{`${answer.text}`}</Text>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                    {splitedAnswers.thirdColumn && splitedAnswers.thirdColumn.length > 0 ? (
                        <div className="answersColumn">
                            {splitedAnswers.thirdColumn.map(answer => {
                                return (
                                    <div className="answerContainer">
                                        <div>
                                            <Text
                                                style={{ fontSize: fontSize }}
                                                className="primary answer"
                                            >
                                                {`${answer.letter}.`}&nbsp;
                                            </Text>
                                        </div>
                                        <div>
                                            <Text
                                                style={{ fontSize: fontSize }}
                                                className="primary answer"
                                            >{`${answer.text}`}</Text>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                </div>
            ) : 
            null}
        </StudioInfoScreen>
    );
};

export const StudioRoundScreen = React.memo(OriginalStudioRoundScreen, (prevProps, nextProps) => {
    let isSameQuestions = true;
    if (nextProps.possibleAnswers) {
        if (prevProps.possibleAnswers?.length !== nextProps.possibleAnswers.length)
            isSameQuestions = false;
        else
            nextProps.possibleAnswers.forEach((answer, index) => {
                if (
                    prevProps.possibleAnswers &&
                    answer.letter !== prevProps.possibleAnswers[index].letter &&
                    answer.text !== prevProps.possibleAnswers[index].text
                )
                    isSameQuestions = false;
            });
    } else isSameQuestions = false;
    return isSameQuestions;
});
