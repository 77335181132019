import { NavigateFunction } from "react-router-dom";
import { setGameStateAction, setNoInternetAction, setTechnicalErrorAction } from "src/Logic/actions/participants/actions";
import { ParticipantsActionTypes } from "src/Logic/actions/participants/types";
import { GameStatus } from "src/Logic/Models/Response/participant/gameStatus";
import { ParticipantRequester } from "src/Logic/Requesters/ParticipantRequester";

export const getGameSate = async (
    token: string,
    requester:ParticipantRequester,
    dispatch: React.Dispatch<ParticipantsActionTypes>,
    navigate: NavigateFunction, 
    ) => {
    if( !navigator.onLine )
    {
        dispatch(setNoInternetAction( true ))
    }
    else
    try {
     	await requester.GetGameState(token).then( async response => { 
            if(response)
            {
                if( response.status === 200)
                {
                    const data:GameStatus = await response.json();
                    dispatch(setGameStateAction(data));
                }
                else if( response.status === 401)
                    navigate('/sign-in');
                else if( response.status === 400)
                {
                    const body = await response.json();
                    if( body.errorCode )
                        dispatch(setTechnicalErrorAction(body.errorCode ));
                }
                else
                {
                    dispatch(setTechnicalErrorAction( response.status ))
                }

            }
        });
    }
    catch (error) {
        console.log(error);
    }
}
