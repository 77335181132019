import styled from "styled-components"

type StyledButtonProps = {
    variant: 'primary' | 'secondary' | 'gradient',
}
const StyledButton = styled.button<StyledButtonProps>`
   
   @media only screen and ( max-width: 426px)
    {
        width: 100%;
        /* height: 40px; */
        overflow-wrap: break-word;
        padding: 11px 15px;
    }
    z-index: 1; 
    cursor: pointer;
    min-width: 313px;
    color: #fff;
    font-weight: bold;
    font-size: 22px;
    font-family: 'Rowdies';
    text-align: center;
    text-transform: uppercase;
    /* height: 50px; */
    padding: 11px 32px;
    background: ${ props => props.variant === 'gradient' ? 'linear-gradient(180deg, #00BF58 0%, #00A8BF 100%)' : 
    props.variant === 'primary' ? props.theme.colors.green.main: '#000000' };
    border-radius: 4px;
    border: none;
    &:disabled {
        opacity: 0.3;
        cursor: default;
    }
    
    &.descktopLabelButton {
        /* height: 70px; */
        font-size: 50px;
        padding: 6px 38px;
        overflow-wrap: break-word;
        font-family: 'Gotham A', 'Gotham B';
    }
`;

type Props = {
    children?: any,
    onClick?: () => void,
    variant?: 'primary' | 'secondary' | 'gradient',
    style?: React.CSSProperties | undefined,
    className?: string | undefined, 
    disabled?: boolean
}

export const Button = ({ children, onClick, variant = 'primary', style, className, disabled }: Props) => {

    return (
        <StyledButton  disabled = { disabled } style = { style } className = { className } onClick = { onClick } variant = { variant }>{ children }</StyledButton>
    )
}