import LogoSvg from '../../assets/images/final-bid.svg'

type Props = {
    className?: string,
    style?: React.CSSProperties | undefined,
}

export const FinalBidLogo = ({ className, style }: Props) => {
    return (
        <img className = { className }  style = { style } src = { LogoSvg } alt="Final Bid" />
    );
}