import styled from "styled-components";

export const PlayerStairsContainer = styled.div`
    display: flex;
    justify-content: center;

    .alternateCorrect{
        .answer:not(.wrong) {
            color: #00BB96;
        }
    }
`;