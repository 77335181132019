import { ParticipantWithWager } from "../Models/General/participant"
import { ToDisplayNumber } from "./number-helpers"

export const makeStats = (participantWithWagers: ParticipantWithWager[] | undefined, correctlyAnswered: string[] | undefined ) => {
        const highestWagerAccInitial: ParticipantWithWager[] = []
        const lowestWager = { 
            text: 'Lowest Wager:',
            value: ''
        }

        const averageWager = {
            text: 'Average Wager:',
            value: '',
        }

        const highestWager = {
            text: 'Highest Wager:',
            value: ''
        }

        const correctAnswers = {
            text: 'Correct Answers:',
            value: correctlyAnswered !== undefined ? correctlyAnswered.join(', ') : '',
        }

        if( participantWithWagers )
        {
            lowestWager.value = `$${ ToDisplayNumber( participantWithWagers.reduce((prevValue, current) => {
                if( current.wagerAmount < prevValue )
                    return current.wagerAmount;
                return prevValue;
            }, 1_000_000))}`; 

            averageWager.value = `$${ ToDisplayNumber(Math.floor(participantWithWagers
                .reduce(( acc, current) => { return acc + current.wagerAmount }, 0) / participantWithWagers.length))}`;

            highestWager.value = participantWithWagers.reduce(( acc, current ) => {
                if( acc.length === 0)
                return [ current ];
                else if( current.wagerAmount > acc[0].wagerAmount )
                   return  [current]
                else if( current.wagerAmount === acc[0].wagerAmount ) 
                    return [ ...acc, current ];
                else return acc;
            }, highestWagerAccInitial ).map((w) => w.name).join(', ');
        }
        return [ lowestWager, averageWager, highestWager, correctAnswers ];
    }