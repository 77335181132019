import { NavigateFunction } from "react-router-dom";
import { setNoInternetAction, setTechnicalErrorAction } from "src/Logic/actions/participants/actions";
import { ParticipantsActionTypes } from "src/Logic/actions/participants/types";
import { ParticipantRequester } from "src/Logic/Requesters/ParticipantRequester";

export const sendAnswer = async (
    answer: string,
    token: string,
    requester:ParticipantRequester,
    dispatch: React.Dispatch<ParticipantsActionTypes>,
    navigate: NavigateFunction, 
    ) => {
    if( !navigator.onLine )
    {
        dispatch(setNoInternetAction( true ))
    }
    else
    try {
     	await requester.Answer( answer, token ).then(async response => { 
            if( response) {
                if(response.status === 200)
                {
                    // dispatch(setGameStateAction(data));
                }
                else if( response.status ===  401)
                {
                    navigate('/sign-in');
                }
                else if( response.status === 400 )
                {
                    const body = await response.json();
                    dispatch(setTechnicalErrorAction( body.errorCode ));
                }
                else
                {
                    dispatch(setTechnicalErrorAction(response.status))
                }
            }
        });
    }
    catch (error) {
        console.log(error);
    }
}
