import styled from "styled-components"
import Money from '../../assets/images/money.png';

const Img = styled.img`
  position: fixed;
  bottom: 0;
  left: 0;
`;

export const BgDollars = () => {
    return (
        <Img src = { Money } alt="" />
    )
}