// Elements
import { Text } from "src/elements/text"

// Styled
import styled from "styled-components";

const Div = styled.div`
        margin-top: ${ props => props.theme.spacing.large };
        width: 100%;
        display: flex;

        .textContainer {
            height: 180px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }

        .text {
            text-align: unset;
            font-size: 20px;
        }

        .description {
            margin-right: ${ props => props.theme.spacing.large };

            .text {
                color: ${ props => props.theme.colors.green.main };
            }
        }
    }
`;

type Props = {
    gameCode: string | undefined,
    gameName: string,
    gameLink: string,
};

export const ConnectionInfo = ({ gameCode, gameName, gameLink }: Props) => {
    return (
        <Div>
                <div className = "description textContainer">
                    <Text>Game Code:</Text>
                    <Text>Game Name:</Text>
                    <Text>Game Link:</Text>
                </div>
                <div className = "textContainer" >
                    <Text>{ gameCode }</Text>
                    <Text>{ gameName }</Text>
                    <Text>{ gameLink }</Text>
                </div>
            </Div>
    )
} 