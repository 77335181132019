import { ErrorCodes } from "../Models/General/technicalErrors";
import { PlayerEnterParams } from "../Models/Requests/PlayerEnterParams";
import { ParticipantEnterResponse } from "../Models/Response/participant/enterResponse";
import { GameStatus } from "../Models/Response/participant/gameStatus";
import { BaseRequester } from "./BaseRequester";

export class ParticipantRequester extends BaseRequester {

    //  public async AutoSignIn(): Promise<void> {
    //     if (this.apiStorage.apiLogin && this.apiStorage.apiPwd) {
    //         const request = {
    //             Email: this.apiStorage.apiLogin,
    //             Password: this.apiStorage.apiPwd,
    //         } as SignInRequest;
    //         await this.MakePostRequest("/api/auth/signIn", request);
    //     }
    // }

    public async EnterToTheGame( params: PlayerEnterParams ): Promise<Response | undefined> {
        return await this.MakePutRequest(`/api/Participant/enter`, params);
    }

    public async GetGameState( token: string ): Promise<Response | undefined> {
        return await this.MakeGetRequest(`/api/Participant/state`, null, new Headers({accessToken: token}))
    }

    public async MakeWager( wager: number | string, token: string ): Promise<Response  | undefined> {
        return  await this.MakePutRequest(`/api/Participant/wager`, { wager }, null, new Headers({accessToken: token}));
    }
    public async ChooseCategory( category: string, token: string ): Promise<Response | undefined> {
         return await this.MakePutRequest(`/api/Participant/category`, { category }, null, new Headers({accessToken: token}));
    }
    public async Answer( letter: string, token: string ): Promise<Response | undefined> {
       return await this.MakePutRequest(`/api/Participant/answer`, { letter }, null, new Headers({accessToken: token}));
    }
}