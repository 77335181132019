import styled from "styled-components";

export const Page = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .desktopLogo {
        position: absolute;
        top: ${props => props.theme.spacing.main};
        left: ${props => props.theme.spacing.main};
    }

    .mrBottomExtraLarge {
        margin-bottom: ${props => props.theme.spacing.extraLarge};
    }
    .mrBottomLarge {
        margin-bottom: ${props => props.theme.spacing.large};
    }
    
    .mrBottomMain {
        margin-bottom: ${props => props.theme.spacing.main};
    }

    .mrBottomSmall {
        margin-bottom: ${props => props.theme.spacing.small};
    }
    .mrBottom100 {
        margin-bottom: 100px;
    }
    .mrRightMain: {
        margin-right: ${props => props.theme.spacing.main};
    }
    .studioBigButton {
        height: 70px;
        width: 547px;
        font-size: 38.5px;
    }
    
    .bottomGradient {
        width: 100%;
        height: 317px;
        bottom: 0;
        background: linear-gradient(5deg, rgba(15, 20, 32, 0.50) 19.72%, rgba(0, 0, 0, 0.00) 56.27%);
        position: absolute;
    }
`;