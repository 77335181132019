import styled from "styled-components";

export const Page = styled.div`
    padding: 0 ${ props => props.theme.spacing.main };
    display: flex;
    flex-direction: column;
    font-family: 'Gotham A', 'Gotham B';
    /* input {
        width: 100%;
    } */

    .inputWrapper {
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .termsConditions {
        font-size: 12px;
        line-height: 20px;
        font-weight: bold;
        text-align: center;
        text-transform: capitalize;
    }
    a {
        color: ${ props => props.theme.colors.green.main };
        text-decoration: none;
        font-size: 12px;
    }

    .technicalErrorMessage {
        font-size: 24px;
        line-height: 30px;
        margin-top: 90px;
        margin-bottom: ${ props => props.theme.spacing.main };
    }

    .offlineMessage {
        margin-bottom: ${ props => props.theme.spacing.main };
    }

    .mrBottomEtraLarge {
        margin-bottom: ${ props => props.theme.spacing.extraLarge };
    }
    .mrBottomLarge {
        margin-bottom: ${ props => props.theme.spacing.large };
    }
    
    .mrBottomMain {
        margin-bottom: ${ props => props.theme.spacing.main };
    }

    .mrBottomSmall {
        margin-bottom: ${ props => props.theme.spacing.small };
    }

`;