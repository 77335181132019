import styled from 'styled-components';

const Div = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 1;

    &.withMargin {
        margin-top: 153px;
    }
    .descktopLabel {
        height: 70px;
        font-size: 50px;
        padding: 0px 38px;
        overflow-wrap: break-word;
    }

    .answers {
        min-height: 316px;
        display: flex;
        justify-content: space-evenly;
        width: 95vw;

        .answersColumn {
            display: flex;
            flex-direction: column;
            max-width: 587px;

            .answerContainer {
                display: flex;
            }
        }
    }

    .text {
        color: #fff;

        &.small {
            font-size: 60px;
        }
        &.question {
            max-width: 80%;
            height: 210px;
            display: flex;
            align-items: center;
        }

        &.correctAnswer {
            min-height: 50px;
            line-height: 90px;
            text-align: center;
        }

        &.correctlyAnsweredParticipants {
            font-size: 30px;
            margin-top: 70px;
            margin-bottom: -100px;
        }

        &.answer {
            min-height: 50px;
            margin-bottom: 12px;
            text-align: start;

            &.fontSizeLarge {
                font-size: 70px;
            }

            &.fontSizeMedium {
                font-size: 60px;
            }

            &.fontSizeSmall {
                font-size: 46px;
            }

            &.fontSizeSmaller {
                font-size: 32px;
            }

            &.small {
                font-size: 32px;
            }
        }

        &.medium {
            font-size: 70px;
        }

        &.primary {
            font-size: 80px;
        }

        &.secondary {
            font-size: 100px;
        }

        &.gradientMain {
            background: ${props => props.theme.colors.gradient.teal};
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &.correctAnswers {
            font-size: 50px;
            font-weight: 600;
            margin-bottom: -50px;
            background: ${props => props.theme.colors.gradient.teal};
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            position: relative;
            line-height: 70px;
        }

        &.correctAnswers:after {
            background: none;
            content: attr(data-text);
            left: 0;
            position: absolute;
            text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
            top: 0;
            z-index: -1;
        }

        &.cuttedWidth {
            width: 80%;
        }

        &.wellVisible {
            text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.50);
            background: none;
            -webkit-text-fill-color: #00BB96;
        }
    }

    .categoryLabel {
        font-size: 76px;
        padding-left: 57px;
        padding-right: 57px;
        text-shadow: 0px 6.11538px 7.64422px rgba(0, 0, 0, 0.5);
    }

    /* Final Bid */
    .finalBitLogoContainer {
        position: absolute;
        top: 46px;
        right: 23px;

        .studioFinalBitLogo {
            transform: rotate(8.83deg);
        }
    }

    .finalistsContainer {
        display: flex;
        justify-content: space-around;
        width: 90vw;

        .finalistData {
            display: flex;
            flex-direction: column;
            align-items: center;
            &.mrRightMain {
                margin-right: ${props => props.theme.spacing.main};
            }
        }
    }

    .finalBindName {
        height: 100px;
    }

    .dFlex {
        display: flex;
    }

    .marginBetweenWinners {
        margin-bottom: 55px;
    }
`;

type Props = {
    children?: any;
    className?: string | undefined;
    style?: React.CSSProperties | undefined;
};

export const StudioInfoScreen = ({ children, className, style }: Props) => {
    return (
        <Div className={className} style={style}>
            {children}
        </Div>
    );
};
