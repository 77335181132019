// Styled
import styled from 'styled-components'
import { Logo } from '../logo'


const HeaderWrapper = styled.header`
    padding-top: ${ props => props.theme.spacing.extraLarge };
    display: flex;
    justify-content: center;
    z-index: 1;
    width: 100%;
}
`

type Props = {
    className?: string,
    style?: React.CSSProperties | undefined,

}

export const Header = ({ className, style, }: Props) => {

    return (
        <HeaderWrapper className = { className } style = { style }>
            <Logo/>
        </HeaderWrapper>
    )
}