/**
 * @description
 * 
 * We can't use own custom headers at all... and it's really sad.
 * 
 * if I set some custom header, I will receive follow request.
 * 
 * DNT: 1
 * Referer: http://localhost:3000/
 * sec-ch-ua: "Chromium";v="92", " Not A;Brand";v="99", "Microsoft Edge";v="92"
 * sec-ch-ua-mobile: ?0
 * test: test
 * User-Agent: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.107 Safari/537.36 Edg/92.0.902.62
 * 
 * As you can see there is no necessary headers at all. But of course i can see my custom header here.
 * And it's doesn't matter how I will do it.
 * 
 * new Request( { .... header: { test: test }} ) 
 * fetch (url, { .... header: { test: test }} )
 * { headers: [["test": "test"]] }
 * { headers: new Headers(["test", "test"]) }
 * 
 * And see follow as example. This request doesn't contain custom headers....
 * 
 * Accept: *\/*
 * Accept-Encoding: gzip, deflate, br
 * Accept-Language: en-US,en;q=0.9
 * Cache-Control: no-cache
 * Connection: keep-alive
 * Cookie: _ga=GA1.1.2037485977.1628124623; _gid=GA1.1.516236064.1628124623; ASP.NET_SessionId=boddw2evdp1d0merzeubxmgb; FanTaggingFilter.Quality=0; FanTaggingFilter.ClipSortingOrder=0; FanTaggingFilter.FeedId=-2; FanTaggingFilter.ProxyReviewStatus=ProxiesToReview; FanTaggingFilter.MasterVideoId=0; FanTaggingFilter.VenueEventID=562; .ASPXAUTH=3496473AA2B85438A0E93DF4290E07825DF454EFFB5C3E6BE1BC81B7198D0B2CAF59859C38512B2FE14D64211BAFC017FCDE20B025BDC647B239D58E7DCACE02FF1B6F0A2FC64EFA445191988A690852F9BE5A543D3A69DB43D8601D8EA6B56D
 * DNT: 1
 * Host: localhost:41211
 * Origin: http://localhost:3000
 * Pragma: no-cache
 * Referer: http://localhost:3000/
 * sec-ch-ua: "Chromium";v="92", " Not A;Brand";v="99", "Microsoft Edge";v="92"
 * sec-ch-ua-mobile: ?0
 * Sec-Fetch-Dest: empty
 * Sec-Fetch-Mode: cors
 * Sec-Fetch-Site: same-site
 * User-Agent: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.107 Safari/537.36 Edg/92.0.902.62
 * 
 * All extensions such axios and other it's just wrapper on native fetch.....
 * 
 */

import { APIStorage } from "../Storage/api-storage";

export class BaseRequester {

    protected apiStorage = new APIStorage();

    private get baseURL() {
        return this.apiStorage.apiUrl;
    };

    protected MakeGetRequest(url: string,
        queryParams: object | null = null,
        headers: Headers | null = null) {
        return this.MakeRequest(url, "get", queryParams, null, headers);
    }

    protected MakePostRequest(url: string, bodyParams: object | null = null, queryParams: object | null = null) {
        return this.MakeRequest(url, "post", queryParams, bodyParams);
    }
    protected MakePutRequest(url: string, bodyParams: object | null = null, queryParams: object | null = null,  headers: Headers | null = null) {
        return this.MakeRequest(url, "put", queryParams, bodyParams, headers);
    }
    protected MakeDeleteRequest(url: string, bodyParams: object | null = null, queryParams: object | null = null,  headers: Headers | null = null) {
        return this.MakeRequest(url, "delete", queryParams, bodyParams, headers);
    }

    private GetBaseURL(): string {
        if (this.baseURL !== "") return this.baseURL;
        return window.location.origin;
    }

    private async MakeRequest(url: string, method: string = "get",
        queryParams: object | null = null,
        bodyParams: object | null = null,
        headers: Headers | null = null): Promise<Response | undefined> {

        const queryData = new URLSearchParams()
        if (queryParams !== null)
            for (const key in queryParams) {
                //@ts-ignore
                const value = queryParams[key];
                queryData.append(key, value);
            }

        const queryUrl = new URL(url, this.GetBaseURL());
        queryUrl.search = queryData.toString();

        const jsonBody = bodyParams !== null ? JSON.stringify(bodyParams) : null;

        if (!headers)
            headers = new Headers();
        headers.append('Content-Type', 'application/json');
        const request = new Request(queryUrl.href, {
            method: method,
            cache: 'no-cache',
            redirect: 'error',
            // credentials: 'include',
            headers: headers,
            body: jsonBody,
        });

        try {
            const response = await fetch(request, {
                redirect: 'follow'
            });
            if (response.redirected) {
                const newPath = `/${window.location.pathname.replace(/^(?:\/\/|[^/]+)*\//, '')}`;
                console.log(`redirect to ${newPath}`);
                window.location.pathname = newPath;
                return;
            }
            return response;
        } catch (error) {
            console.error(error);
        }
    }
}