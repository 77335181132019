// Enums
import { ErrorCodes } from "src/Logic/Models/General/technicalErrors";
import { GameStatus } from "src/Logic/Models/Response/participant/gameStatus";

// Types
import { ParticipantRequester } from "src/Logic/Requesters/ParticipantRequester";

// ----------------------------- Game State -----------------------------
export const SET_GAME_STATE = 'SET_GAME_STATE';
export type SetGameStateActionType = {
    type: typeof SET_GAME_STATE;
    payload:GameStatus;
};

export type SetGameStateContract = (payload: GameStatus) => SetGameStateActionType;

// ----------------------------- Requester State -----------------------------
export const SET_REQUESTER = 'SET_REQUESTER';
export type SetRequesterActionType = {
    type: typeof SET_REQUESTER;
    payload: ParticipantRequester;
};

export type SetRequesterContract = (payload: ParticipantRequester) => SetRequesterActionType;

// ----------------------------- Technical Error -----------------------------
export const SET_TECHNICAL_ERROR = 'SET_TECHNICAL_ERROR';
export type SetTechnicalErrorActionType = {
    type: typeof SET_TECHNICAL_ERROR;
    payload: ErrorCodes | number | null;
};

export type SetTechnicalErrorContract = (payload: ErrorCodes | number | null) => SetTechnicalErrorActionType;

// ----------------------------- No Internet Error -----------------------------
export const SET_NO_INTERNET = 'SET_NO_INTERNET';
export type SetNoInternetActionType = {
    type: typeof SET_NO_INTERNET;
    payload: boolean;
};

export type SetNoInternetContract = (payload: boolean) => SetNoInternetActionType;

// ----------------------------- Cicle Requesting -----------------------------
export const SET_REQUESTING_STARTED = 'SET_REQUESTING_STARTED';
export type SetRequestingStartedActionType = {
    type: typeof SET_REQUESTING_STARTED;
    payload: boolean;
};

export type  SetRequestingStartedContract = (payload: boolean) => SetRequestingStartedActionType;



export type ParticipantsActionTypes = 
SetGameStateActionType                  |
SetTechnicalErrorActionType             |
SetNoInternetActionType                 |
SetRequesterActionType                  |
SetRequestingStartedActionType          