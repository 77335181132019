import { Header } from "src/elements/header";
import { ProgressBar } from "src/elements/progressBar"
import styled from "styled-components"

const Div = styled.div`
    margin-top: ${ props => props.theme.spacing.extraLarge };
    z-index: 1;
    display: flex;
    justify-content: center;

    .progressMobile {
        width: 238px;
    }
`;

type Props = { 
    label?: string,
    progress?: number,
}

export const LoadingScreen = ({ label, progress}: Props) => {
    return ( 
        <>
            <Header />
            <Div>
                <ProgressBar className = "progressMobile" label = { label } progress = { progress }/>
            </Div>
        </>
    )
}