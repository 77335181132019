import styled from "styled-components"

const Div = styled.div`
    font-family: 'Gotham A', 'Gotham B';
    font-style: normal;
    font-size: 14px;
    /* line-height: 20px; */
    text-align: center;
    font-weight: bold;
    color: #000000; 
`;

type Props = {
    children?: any,
    style?: React.CSSProperties | undefined,
    className?: string | undefined, 
    dataText?: string,
    wrapperRef?: React.RefObject<HTMLDivElement>,
}
export const Text = ({ children, className, style, dataText, wrapperRef}: Props) => {
    return (
        <Div 
            ref = { wrapperRef }
            data-text={ dataText } 
            className = {`text${ className ? ` ${ className }` : '' }` } 
            style = { style }>
            { children }
        </Div>
    )
}