import { NavigateFunction } from "react-router-dom";
import { GameStatuses } from "src/Logic/Models/General/gameStatuses";
import { ErrorCodes } from "src/Logic/Models/General/technicalErrors";
import { OperatorGameState } from "src/Logic/Models/Response/operator/gameStatus";
import { OperatorRequester } from "src/Logic/Requesters/OperatorRequester";

export const getGameState = async (
    roomCode: string | number,
    requester: OperatorRequester,
    setAdminPageState: React.Dispatch<React.SetStateAction<OperatorGameState | undefined>>,
    setTechnicalError: React.Dispatch<React.SetStateAction<ErrorCodes | number | null>>,
    setIsNoInternet: React.Dispatch<React.SetStateAction<boolean>>,
    navigate: NavigateFunction,
    ) => {
    if( !navigator.onLine )
    {
        setIsNoInternet( true );
    }
    else
    try {
     	await requester.GetGameState( roomCode ).then( async response => { 
            if( response )
            {
                if( response.status === 200 )
                {
                    const data:OperatorGameState = await response.json();
                    if( data.gameStatus === GameStatuses.notStarted )
                        navigate('/sign-in')
                    setAdminPageState(data);
                }
                else if( response.status === 401 )
                    navigate('/sign-in');
                else if ( response.status === 400 )
                {
                    const body = await response.json();
                    if( body.errorCode )
                        setTechnicalError( body.errorCode );
                }
                else
                    setTechnicalError( response.status )
            }
        });
    }
    catch (error) {
        console.log(error);
    }
}
