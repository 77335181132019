import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfoScreen } from 'src/components/participant/infoScreen';
import { LogInScreen } from 'src/components/participant/logInScreen';
import { Button } from 'src/elements/buttons';
import { Header } from 'src/elements/header';
import { Text } from 'src/elements/text';
import { enterToTheGame } from 'src/Logic/API/Participant/enterToTheGame';
import { ErrorCodes } from 'src/Logic/Models/General/technicalErrors';
import { ParticipantRequester } from 'src/Logic/Requesters/ParticipantRequester';
import { Page } from '../Players/styles';
import { destroyCookie, parseCookies } from 'nookies';

export const PlayerSignInPage = () => {
    const requester = useMemo<ParticipantRequester>(() => new ParticipantRequester(), []);

    const [isRoomCodeWrong, setIsroomCodeWrong] = useState(false);
    const [technicalError, setTechnicalError] = useState<ErrorCodes | number | null>(null);
    const [isNoInternet, setNoIntenet] = useState(false);
    const [name, setName] = useState('');
    const [roomCode, setRoomCode] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const cookies = parseCookies();
        const storedToken = cookies.token;
        if (storedToken) destroyCookie(null, 'token');

        console.log(parseCookies());
    }, []);

    useEffect(() => {
        if (!isNoInternet && roomCode !== '' && name !== '') handleEnter();
    }, [isNoInternet]);

    const handleEnter = () => {
        enterToTheGame(
            { roomCode, name },
            requester,
            setIsroomCodeWrong,
            setTechnicalError,
            setNoIntenet,
            navigate,
        );
    };

    const errorRenderer = (technicalError: ErrorCodes | number | null, isNoInternet: boolean) => {
        let message = '';
        if (technicalError === ErrorCodes.ParticipantAlreadyExists)
            message = `Participant ${name} is already online...`;
        else if (technicalError === ErrorCodes.MaximumPlayerCount)
            message = `The room is already full, you cannot join.`;
        else if (technicalError === ErrorCodes.GameNotStarted)
            message = "The game didn't start yet, you cannot join.";
        else if (technicalError === ErrorCodes.EnteringWrongGameState)
            message = 'This game has already started, and you cannot join now.';
        else if (technicalError) message = technicalError.toString();

        if (technicalError)
            return (
                <InfoScreen>
                    <Header />
                    <Text className="technicalErrorMessage">
                        A technical error occurred. {message}
                    </Text>
                    <Button
                        onClick={() => {
                            setTechnicalError(null);
                        }}
                    >
                        OK
                    </Button>
                </InfoScreen>
            );
        if (isNoInternet)
            return (
                <InfoScreen>
                    <Header />
                    <Text className="technicalErrorMessage" style={{ marginBottom: 0 }}>
                        No internet connection.
                    </Text>
                    <Text className="offlineMessage">You are offline. Check your connection.</Text>
                    <Button
                        onClick={() => {
                            setNoIntenet(false);
                        }}
                    >
                        Retry
                    </Button>
                </InfoScreen>
            );

        return null;
    };

    return (
        <Page>
            {!technicalError && !isNoInternet ? (
                <LogInScreen
                    isRoomCodeWrong={isRoomCodeWrong}
                    setName={setName}
                    setRoomCode={setRoomCode}
                    onEnter={handleEnter}
                    roomCode={roomCode}
                    name={name}
                />
            ) : null}
            {errorRenderer(technicalError, isNoInternet)}
        </Page>
    );
};
