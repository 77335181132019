import { Label } from 'src/elements/label';
import { Text } from 'src/elements/text';
import { Container } from './styles';
import { PlayersStairs } from '../playersStairs';
import { AudienceGameState } from 'src/Logic/Models/Response/audience/gameStatus';
import { GameStatuses } from 'src/Logic/Models/General/gameStatuses';
import { ToDisplayNumber } from 'src/Logic/Helpers/number-helpers';

type Props = {
    gameState: AudienceGameState;
};

export const AudienceScreen = ({ gameState }: Props) => {
    const displayEliminated = gameState.gameStatus === GameStatuses.displayEliminated;
    const eliminatedPlayerData = gameState.participants.find(({ eliminatedInCurrentRound }) => eliminatedInCurrentRound );
    return (
        <Container>
            <header className={'withTopMarging'}>
                {gameState?.roundNumber !== undefined ? (
                    <Text className="round">{`ROUND ${gameState?.roundNumber}`}</Text>
                ) : null}
                {gameState?.roundCategory !== undefined ? (
                    <Label className="category">{gameState?.roundCategory}</Label>
                ) : null}
            </header>
            <PlayersStairs
                participants={gameState?.participants.filter(({ eliminatedInCurrentRound })=>!eliminatedInCurrentRound)}
                gameStatus={gameState?.gameStatus}
            />
            { displayEliminated ? 
                <Text className='eliminationMessage'>Player { eliminatedPlayerData?.name } Is Eliminated With A Wager Of ${ ToDisplayNumber(eliminatedPlayerData?.wager) }</Text>
            // : null }
            : <div /> }
            {/* <div /> */}
        </Container>
    );
};
