import * as types from './types';

export const setRequesterAction: types.SetRequesterContract = (payload) => ({
    type: types.SET_REQUESTER,
    payload,
});

export const setGameStateAction: types.SetGameStateContract = (payload) => ({
    type: types.SET_GAME_STATE,
    payload,
});

export const setTechnicalErrorAction: types.SetTechnicalErrorContract = (payload) => ({
    type: types.SET_TECHNICAL_ERROR,
    payload,
});

export const setNoInternetAction: types.SetNoInternetContract = (payload) => ({
    type: types.SET_NO_INTERNET,
    payload,
});

export const setRequestingStartedAction: types.SetRequestingStartedContract = (payload) => ({
    type: types.SET_REQUESTING_STARTED,
    payload,
});
