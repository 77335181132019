import { Text } from 'src/elements/text';
import styled from 'styled-components';

const Container = styled.div`
    position: absolute;
    top: ${props => props.theme.spacing.main};
    right: ${props => props.theme.spacing.large};
    text-align: right;

    div {
        text-align: right;
        font-size: 30px;
    }

    .mainGradientText {
    }

    .namesList {
        color: #fff;
        font-family: 'Gotham A', 'Gotham B';
    }
`;

type Props = {
    names: string[] | undefined;
};

export const WhoIsLeft = ({ names }: Props) => {
    return (
        <Container>
            <Text className="mainGradientText">Who's Left:</Text>
            <Text className="namesList">{names?.join(', ')}</Text>
        </Container>
    );
};
