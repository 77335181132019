import { Button } from "src/elements/buttons"
import { Text } from "src/elements/text"
import { StudioInfoScreen } from "../StudioInfoScreen"

type Props = {
    onRetry: () => void;
}
 
export const StudioNoInternet = ({ onRetry } : Props) => {
    return (
        <StudioInfoScreen>
            <Text className = "primary mrBottomExtraLarge" >No internet connection.</Text>
            <Text className = "small mrBottomExtraLarge" >You are offline. Check your connection.</Text>
            <Button onClick = { onRetry } className = "studioBigButton" variant = "gradient" >RETRY</Button>
        </StudioInfoScreen>
    )
}