import { FinalBidLogo } from "src/elements/finalBidLogo"
import { Text } from "src/elements/text"
import { ToDisplayNumber } from "src/Logic/Helpers/number-helpers"
import { ParticipantWithWager } from "src/Logic/Models/General/participant"
import { StudioInfoScreen } from "../StudioInfoScreen"

type Props = {
    participants?: ParticipantWithWager[] | undefined
}

export const FinalBindStudioScreen = ({ participants }: Props) => {
    return (
        <StudioInfoScreen>
            <div className = 'finalBitLogoContainer'>
                <FinalBidLogo className = "studioFinalBitLogo"/>
            </div>
            <div className = "finalistsContainer">
                { participants ? participants.map(( participant, index ) => { return (
                    <div className = {`finalistData${index === 0 ? " mrRightMain" : ""}`}>
                        <Text className = "secondary finalBindName mrBottomExtraLarge">{participant.name }</Text>
                        <div className = "dFlex">
                            <Text className = "medium">Balance:&nbsp;</Text>
                            <Text className = "primary"> { `$${ ToDisplayNumber(participant.balance)}` }</Text>
                        </div>
                        <div className = "dFlex">
                            <Text className = "medium">Wager:&nbsp;</Text>
                            <Text className = "primary">{ `$${ ToDisplayNumber(participant.wagerAmount)}` }</Text>
                        </div>
                    </div>
                )}) : null}
            </div>
        </StudioInfoScreen>
    )
}