import { Text } from 'src/elements/text';
import { ToDisplayNumber } from 'src/Logic/Helpers/number-helpers';
import { StudioInfoScreen } from '../StudioInfoScreen';

type Props = {
    wager?: number;
    playerNames: string[] | undefined;
};
export const StudioWagerEliminationScreen = ({ wager, playerNames }: Props) => {
    return (
        <StudioInfoScreen>
            {playerNames && playerNames.length > 0 ? (
                <>
                    <Text className="primary mrBottomLarge">{`With a wager of $${
                        wager !== undefined ? ToDisplayNumber(wager) : ''
                    } `}</Text>
                    <Text className="primary">
                        {playerNames.join(', ')} {playerNames.length > 1 ? 'have' : 'has'} been
                        eliminated.
                    </Text>
                </>
            ) : (
                <Text className="primary cuttedWidth">
                    Nobody has been eliminated due to the same wager.
                </Text>
            )}
        </StudioInfoScreen>
    );
};
