import { EnvironmentStorage } from "./environment-storage";

export class APIStorage extends EnvironmentStorage {
    private static readonly apiUrlConst = "API_URL";
    private static readonly apiLoginConst = "API_LOGIN";
    private static readonly apiPwdConst = "API_PWD";
    
    public get apiUrl() {
        return this.getString(APIStorage.apiUrlConst, "");
    }

    public get apiLogin() {
        return this.getString(APIStorage.apiLoginConst, "");
    }

    public get apiPwd() {
        return this.getString(APIStorage.apiPwdConst, "");
    }
}
