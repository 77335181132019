import { ParticipantsActionTypes } from "../actions/participants/types";
import { ErrorCodes } from "../Models/General/technicalErrors";
import { GameStatus } from "../Models/Response/participant/gameStatus";
import { ParticipantRequester } from "../Requesters/ParticipantRequester";


export type StateType = {
    state?: GameStatus,
    technicalError: ErrorCodes | number | null,
    noInternet: boolean,
    requester?: ParticipantRequester,
    requestingStarted: boolean,
} 

export const initialState: StateType = {
    technicalError: null,
    noInternet: false,
    requestingStarted: false,
};

const styles = [
'background: red',
'background: orange',
'color: #ffbf00',
'background: yellowgreen',
'color: #139BFE',
'background: steelblue',
'background: darkviolet'
];

export const participantReducer = (prevState: StateType, action: ParticipantsActionTypes):StateType => {

    console.group ( `%c ${action.type}`, styles[4] );
        console.log (`%c Payload `, styles[2], action.payload);
    console.groupEnd ();

    switch (action.type) {
        case 'SET_REQUESTER': {
            return { ...prevState, requester: action.payload };
        }

        case 'SET_GAME_STATE': {
            return { ...prevState, state: action.payload };
        }

        case "SET_NO_INTERNET": {
            return { ...prevState, noInternet: action.payload };
        }

        case "SET_REQUESTING_STARTED": {
            return { ...prevState, requestingStarted: action.payload };
        }
        
        default:
            return prevState;
    }

}