import { Text } from 'src/elements/text';
import styled from 'styled-components';

type Props = {
    stat?: string | undefined;
    value?: string | number | undefined;
};
const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 390px;
`;

export const GameStat = ({ stat, value }: Props) => {
    return (
        <Div>
            <Text className="gradientMain wellVisible" style={{ fontSize: 40, marginBottom: 20 }}>
                {stat}
            </Text>
            <Text style={{ fontSize: 40, overflowWrap: 'anywhere' }}>{value}</Text>
        </Div>
    );
};
