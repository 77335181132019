import styled from "styled-components";

const Div = styled.div`
    font-family: 'Gotham A', 'Gotham B';
    z-index: 1;
    /* width: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #00BF58 0%, #00A8BF 100%);
    font-size: 19.8606px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 6.11538px 7.64422px rgba(0, 0, 0, 0.5);
    width: fit-content;
    padding: 7px 15px;

    @media (max-width: 426px) {
        text-shadow: 0px 1.58885px 1.98606px rgba(0, 0, 0, 0.5);
    }

    
`;

type Props = {
    children?: any;
    style?: React.CSSProperties | undefined,
    className?: string | undefined, 
}

export const Label = ({ children, className, style }: Props) => {
    return (
        <Div className = { className } style = { style }>
            { children }
        </Div>
    )
}