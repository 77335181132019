export const defaultTheme = {
    spacing: {
        extraLarge: '70px',
        large: '40px',
        main: '30px',
        small: '20px'
    },
    colors: {
        black: '#000',
        gray: {
            main: '#CCCCCC',
            input: '#979797',
        },
        green: { 
            main: '#01B684',
        },
        red: {
            main: '#F15155',
        },
        gradient: {
            teal: '-webkit-linear-gradient(#00BF58, #00A8BF)',
        },
        
    },
}