export enum ErrorCodes
{
	GameNotStarted = 'gameNotStarted', // error message: The game didn't start yet, you cannot join.
	RoundNotStarted = 'roundNotStarted',
	AlreadyWaged = 'alreadyWaged',
	OutOfBalance = 'outOfBalance',
	YouCanNotChooseCategory = 'youCanNotChooseCategory',
	CategoryAlreadySelected = 'categoryAlreadySelected',
	NoQuestionForRoundFound = 'noQuestionForRoundFound',
	CategoryInNotSelectedForRound = 'ategoryInNotSelectedForRound',
	AlreadyAnsweredQuestion = 'alreadyAnsweredQuestion',
	ThereIsNoCorrectAnswerForQuestion = 'thereIsNoCorrectAnswerForQuestion',
	ThereIsNoAnswerWithAnswerCode = 'thereIsNoAnswerWithAnswerCode',
	MaximumPlayerCount = 'maximumPlayerCount', // error message: The room is already full, you cannot join.
	WrongGameState = 'wrongGameState' ,
	EnteringWrongGameState = 'enteringWrongGameState', // error message: This game has already started, and you cannot join now.
	ParticipantAlreadyExists = 'participantAlreadyExists', // error message: participant {name} is already online. OR: a participant with the specified name is already online.
	NotAllParticipantsEntered = 'notAllParticipantsEntered',
	WeHaveNoRoundsLeft = 'weHaveNoRoundsLeft',
	NoParticipantToChooseCategory = 'noParticipantToChooseCategory',
}