import { Button } from 'src/elements/buttons';
import { Input } from 'src/elements/input';
import { Text } from 'src/elements/text';
import styled from 'styled-components';

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        font-size: 40px;
        text-align: center;
        text-transform: capitalize;
        margin-bottom: 100px;
    }

    .inputLabel {
        text-transform: capitalize;
        font-size: 20px;
    }
`;

type Props = {
    title?: string;
    onEnterCode?: (roomCode: string) => void;
    buttonText?: string;
    onButtonClick?: () => void;
    buttonDisabled?: boolean;
    errorText?: string | undefined;
};

export const DesktopRoomEntrance = ({
    buttonText,
    title,
    onEnterCode,
    onButtonClick,
    buttonDisabled,
    errorText,
}: Props) => {
    return (
        <Div>
            <Text className="title">{title}</Text>
            <div>
                <Text className="inputLabel mrBottomMain">please enter a Room code</Text>
                <Input
                    error={errorText}
                    className="mrBottomMain"
                    placeholder="enter 4 letter code"
                    onChange={e => {
                        if (onEnterCode) onEnterCode(e.target.value);
                    }}
                    maxLength={4}
                />
                <Button
                    disabled={buttonDisabled}
                    onClick={() => {
                        if (onButtonClick) onButtonClick();
                    }}
                >
                    {buttonText}
                </Button>
            </div>
        </Div>
    );
};
