// import { useContext } from "react"
// import { ParticipantContext } from "src/pages/PlayersPage"
import { eliminationStatuses } from "../Models/General/eliminationStatuses";
import { GameStatuses } from "../Models/General/gameStatuses";
import { GameStatus } from "../Models/Response/participant/gameStatus";

export enum AnswerStates
{
    Waiting = 'waiting',
    Correct =  'correct',
    Wrong =  'wrong',
    NotAnswered = 'notAnswered'
}

export const useParticipantLogic = () => {
    // const { participantState: { state } } = useContext(ParticipantContext);

    const checkComponentsToDisplay = (state?: GameStatus | undefined) => { 
        
        const componentsToDisplay = { 
            enterScreen: false,
            playersLoading: false,
            categorySelection: false,
            categorySelected: false,
            waitingForSelection: false,
            makingWager: false,
            waitingOtherWagers: false,
            elimination: false,
            youEliminated: false,
            noOneEliminated: false,
            answer: 
            { 
                show: false,
                state: AnswerStates.NotAnswered,
            },
            winner: false,
        }

        if( state !== undefined)
        {
            if( state.eliminationStatus === eliminationStatuses.NotEliminated)
            {
                switch( state.gameStatus )
                {
                    case GameStatuses.notStarted: {
                        componentsToDisplay.playersLoading = true;
                        break;
                    }

                    case GameStatuses.enteringRoom: {
                        componentsToDisplay.playersLoading = true;
                        break;
                    }

                    case GameStatuses.makingWagers: {
                        if(state.yourWager >= 0)
                            componentsToDisplay.waitingOtherWagers = true;
                        else
                            componentsToDisplay.makingWager = true;
                        break;
                    }

                    case GameStatuses.selectingCategory: {
                        if( state.youNeedSelectCategory &&  !state.roundCategory )
                            componentsToDisplay.categorySelection = true;
                        else if( state.youNeedSelectCategory && state.roundCategory )
                        {
                            componentsToDisplay.categorySelected = true;
                        }
                        else if( !state.roundCategory )
                        {
                            componentsToDisplay.waitingForSelection = true;
                        }
                        break;
                    }

                    case GameStatuses.displayEliminated: {
                        if( state.eliminationStatus === eliminationStatuses.NotEliminated)
                        {
                            if( !state.eliminatedParticipantNames || state.eliminatedParticipantNames.length === 0)
                                componentsToDisplay.noOneEliminated = true;
                            else
                                componentsToDisplay.elimination = true;
                        }
                        else
                            componentsToDisplay.youEliminated = true;
                        break;
                    }

                    case GameStatuses.answering: {
                        componentsToDisplay.answer.show = true;
                        if( state.yourAnswer?.letter === undefined )
                            componentsToDisplay.answer.state = AnswerStates.NotAnswered;
                        else if( state.yourAnswer?.letter )
                            componentsToDisplay.answer.state = AnswerStates.Waiting;
                        break;
                    }

                    case GameStatuses.checkingAnswers:{
                        componentsToDisplay.answer.show = true;
                        if( (state.yourAnswer && state.yourAnswer.letter ) === ( state.correctAnswer && state.correctAnswer.letter ) )
                            componentsToDisplay.answer.state = AnswerStates.Correct;
                        else
                            componentsToDisplay.answer.state = AnswerStates.Wrong;
                        break;
                    }
                    case GameStatuses.displayWinner: {
                        componentsToDisplay.winner = true;
                    }

                }
            }
            else
                componentsToDisplay.youEliminated = true;
        }
        else {
            componentsToDisplay.enterScreen = true;
        }

        return componentsToDisplay;
    }

    return { 
        checkComponentsToDisplay,
    }
}