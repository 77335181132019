import { Button } from "src/elements/buttons";
import { Text } from "src/elements/text";
import styled from "styled-components"

const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
        text-transform: uppercase;
        font-size: 40px;
        margin-bottom: ${ props => props.theme.spacing.extraLarge };
        margin-bottom: 60px;
    }

    .secondaryFont {
        font-size: 20px;
    }
`;

type Props = {
    onRetry: () => void;
}

export const AdminNoInternet = ( { onRetry }: Props ) => {
    return (
        <Div>
            <Text className = "title">NO INTERNET CONNECTION.</Text>
            <Text className = "title secondaryFont">You are offline. Check your connection.</Text>
            <Button onClick = { onRetry }>RETRY</Button>
        </Div>
    )
}