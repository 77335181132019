import styled from "styled-components";

export const Page = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Gatham A', 'Gotham B';
    .mrBottomExtraLarge {
        margin-bottom: ${ props => props.theme.spacing.extraLarge };
    }
    .mrBottomLarge {
        margin-bottom: ${ props => props.theme.spacing.large };
    }
    
    .mrBottomMain {
        margin-bottom: ${ props => props.theme.spacing.main };
    }

    .mrBottomSmall {
        margin-bottom: ${ props => props.theme.spacing.small };
    }
    .mrBottom100 {
        margin-bottom: 100px;
    }

    .descktopLogo {
        position: absolute;
        top: ${ props => props.theme.spacing.main };
        left: ${ props => props.theme.spacing.main };
    }
`;