import { useState } from "react";
import { Button } from "src/elements/buttons";
import { Header } from "src/elements/header";
import { Input } from "src/elements/numeric-input";
import { Text } from "src/elements/text";
import { FromDisplayNumber, ToDisplayNumber } from "src/Logic/Helpers/number-helpers";
import styled from "styled-components"


const Div = styled.div`
    margin-top: ${ props => props.theme.spacing.extraLarge };
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .wagerText { 
        font-size: 16px;
        line-height: 20px;
        text-transform: capitalize;
        font-weight: bold;
    }

    .balanceText {
        font-weight: bold;
        font-size: 24px;
        line-height: 20px;
    }
`;

type Props = {
    makeWager: ( value: number ) => void,
    balance:  number,
}

export const  WagerScreen = ({ makeWager, balance } : Props ) => {

    const [ wagerValue, setWagerValue ] = useState<number>();
    const [ isError, setIsError ] = useState( false );

    const handleWagerChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        if (event.target.value !== undefined)
        {
            const wagerNumber = FromDisplayNumber(event.target.value);

            if( wagerNumber > balance || wagerNumber % 1 !== 0 || wagerNumber < 0)
                setIsError( true )
            else
                setIsError( false )

            setWagerValue(wagerNumber);
        }
    }

    return ( 
        <>
            <Header />
            <Div>
                <Text className = "wagerText">your bank balance:</Text>
                <Text className = "mrBottomEtraLarge balanceText" style = {{ fontSize: '24px'}}><b>{`$${ToDisplayNumber(balance)}`}</b></Text>
                <Input 
                    error = { isError ? 'incorrect wager amount.' : undefined }
                    onChange = { handleWagerChange } 
                    className = "mrBottomMain"  
                    label = "How much would you like to wager?"
                    placeholder = "Enter Wager"/>
                <Button 
                    disabled = { wagerValue === undefined || isError } 
                    onClick = { () => { if( wagerValue !== undefined ) makeWager( wagerValue )}}>Enter</Button>
            </Div>
        </>
    )
}