// Moment
import moment from "moment";

// Types
import { GameStatuses } from "src/Logic/Models/General/gameStatuses";
import { AnswerType, ParticipantType, WagerType } from "src/Logic/Models/Response/operator/gameStatus";

// Helpers
import { ToDisplayNumber } from "src/Logic/Helpers/number-helpers";
import { getPaticipantIcon } from "./getParticipantIcon";

export type PlayerDataType = {
    name: string,
    icon: string,
    id: number,
    answer: string,
    wager: string,
    disabled: boolean,
    isAnswerCorrect: boolean;
    isWinner: boolean;
    balance: string | undefined,
}

enum PlayerDataWagers {
    Entering = 'Entering...',
    NoWager = 'No Wager',
} 

export const makePlayersData = ({
    answers,
    correctAnswer,
    expirationTime,
    gameStatus,
    participants,
    wagers,
    winners,
}: {
    participants: ParticipantType[], 
    wagers: WagerType[], 
    answers: AnswerType[], 
    gameStatus: GameStatuses,
    winners?: string[] | undefined,
    correctAnswer?: string | undefined,
    expirationTime?: string | undefined,
}): PlayerDataType[] => {

    return participants.map(( participant ) => { 
        let wager = '';
        let answer = '';
        let isAnswerCorrect = true;
        let disabled = false;
        let icon = '';
        let isWinner = false;
        let balance: string | undefined = `$${ToDisplayNumber(participant.balance)}`;

        icon = getPaticipantIcon(participant.id)

        if( gameStatus === GameStatuses.makingWagers ||  gameStatus === GameStatuses.displayEliminated)
        {
            const findedWager = wagers.find(( wager ) => wager.id === participant.id );
            if( expirationTime && moment(expirationTime).isSameOrAfter(moment(), 'second') && !findedWager )
            {
                wager = PlayerDataWagers.NoWager;
                disabled = true;
            }
            else
            if(  !findedWager )
            {
                wager = PlayerDataWagers.Entering;
                disabled = true;
            }
            else
            {
                wager = `$${ToDisplayNumber(findedWager.wager)}`;
            }
        }
        else if( gameStatus === GameStatuses.answering || gameStatus === GameStatuses.checkingAnswers)
        {
            const findedWager = wagers.find(( wager ) => wager.id === participant.id );
            if( findedWager )
                wager = `$${ToDisplayNumber(findedWager.wager)}`;
            const findedAnswer = answers.find(( answer ) => answer.id === participant.id );
            if( expirationTime && moment(expirationTime).isSameOrAfter(moment(), 'second') && !findedAnswer )
            {
                answer = 'No Answer';
                disabled = true;
            }
            else 
            if( !findedAnswer )
                {
                    disabled = true;
                }
                else
                {
                    answer = findedAnswer.letter;
                }
            if( gameStatus === GameStatuses.checkingAnswers && findedAnswer && correctAnswer && findedAnswer.letter !== correctAnswer )
                isAnswerCorrect = false;
        }
        else if( gameStatus === GameStatuses.displayWinner )
        {
            if( winners && winners.length > 0 && winners.includes(participant.name))
                isWinner = true;
        }
        else
        {
            balance = '';
        }

        return { 
            name: participant.name, 
            id: participant.id, 
            answer, 
            wager,
            disabled, 
            isAnswerCorrect,
            icon,
            isWinner,
            balance,
        }});

}