// Elements
import { Player } from 'src/elements/player';

// Styled
import styled from 'styled-components';

// Helpers
import { PlayerDataType } from './helpers/makePlayersData';

const Div = styled.div`
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    height: 170px;
`;

type Props = {
    className?: string;
    style?: React.CSSProperties | undefined;
    participants: PlayerDataType[] | undefined;
    displayWinners: boolean;
    onDelete: (name: string) => void;
    canDelete: boolean;
};

export const PlayersRow = ({
    style,
    className,
    participants,
    displayWinners,
    onDelete,
    canDelete,
}: Props) => {
    return (
        <Div style={style} className={className}>
            {!displayWinners
                ? participants?.map(player => {
                      return (
                          <Player
                              canDelete={canDelete}
                              onDelete={onDelete}
                              key={player.id}
                              data={player}
                          />
                      );
                  })
                : participants
                      ?.filter(player => player.isWinner)
                      .map(player => {
                          return (
                              <Player
                                  canDelete={canDelete}
                                  onDelete={onDelete}
                                  key={player.id}
                                  data={player}
                              />
                          );
                      })}
        </Div>
    );
};
