import moment from "moment";
import { makePlayersData, PlayerDataType } from "src/components/admin/playersRow/helpers/makePlayersData";
import { GameStatuses, GameStatusesForChange } from "../Models/General/gameStatuses";
import { OperatorGameState } from "../Models/Response/operator/gameStatus"

enum ButtonTexts { 
    StartRound  = 'START ROUND',                
    Eliminate = 'ELIMINATE',
    Question = 'DISPLAY QUESTION',
    CorrectAnswer = 'DISPLAY CORRECT ANSWER',
    NextRound = 'NEXT ROUND',
    Proceed = 'PROCEED',
}

enum ProgressMessages {
    Connecting = 'Waiting for other players to join...',
    AllConnected = 'All the players are in!',
    MakingWagers = 'Players are entering wagers...',
    AllWagersIn = 'All wagers are in!',
    NoWagers = 'No wagers received',
    Answering = 'Players are selecting answers...',
    AllAnswersIn = 'All answers are in!',
}

export type ButtonSettingsType = {
    text: ButtonTexts,
    statusForChange: GameStatusesForChange,
    display: boolean,
    disabled: boolean,
}

export type ControlPageSettingsType = {
    gameInfo: boolean;
    round: boolean;
    label: boolean;
    progress: {
        show: boolean;
        message: ProgressMessages;
        percentage: number;
    };
    question: boolean;
    eliminatedPlayer: boolean;
    categorySelecting: boolean;
    displayWinners: boolean;
    checkingAnswers: boolean;
    firstRowParticipants: undefined | PlayerDataType[];
    secondRowParticipants: undefined | PlayerDataType[];
    button: ButtonSettingsType,
}

export const useAdminPageLogic = () => {
    
    const getControlPageSettings = (gameState: OperatorGameState | undefined ) => {

        const settings: ControlPageSettingsType = {
            gameInfo: false,
            round: false,
            label: false,
            progress:
            {
                show: false,
                message: ProgressMessages.Connecting,
                percentage: 0,
            },
            question: false,
            eliminatedPlayer: false,
            categorySelecting: false,
            displayWinners: false,
            checkingAnswers: false,
            firstRowParticipants: undefined,
            secondRowParticipants: undefined,
            button: {
                text: ButtonTexts.StartRound,
                statusForChange: GameStatusesForChange.newRound,
                disabled: false,
                display: false,
            },
        }    

        if( gameState && gameState.participants ) {
            
            settings.firstRowParticipants = makePlayersData({
                participants: gameState.participants.slice(0, 5), 
                wagers: gameState.participantWagers, 
                answers: gameState.participantAnswers,
                gameStatus: gameState.gameStatus,
                winners: gameState.winnerNames,
                correctAnswer: gameState.correctAnswer?.letter,
                expirationTime: gameState.expirationTime,
            });

            settings.secondRowParticipants =  makePlayersData({
                participants: gameState.participants.slice(5, 11), 
                 wagers: gameState.participantWagers, 
                answers: gameState.participantAnswers,
                gameStatus: gameState.gameStatus,
                winners: gameState.winnerNames,
                correctAnswer: gameState.correctAnswer?.letter,
                 expirationTime: gameState.expirationTime, 
            });
            

            switch( gameState.gameStatus )
            {
                case GameStatuses.enteringRoom: {
                        settings.progress.show = true;

                    if( gameState.participants.length === 0)
                    {
                        settings.gameInfo = true;
                        settings.button.display = false;
                        settings.progress.message = ProgressMessages.Connecting;

                    }
                    else    
                    {   
                        settings.progress.percentage = (gameState.participants.length / gameState.maxParticipantCount) * 100; 
                        settings.button.display = true;
                        if( gameState.participants.length === gameState.maxParticipantCount )
                        {
                            settings.progress.message = ProgressMessages.AllConnected;
                            settings.button.disabled = false;
                        }
                        else
                        {
                            settings.progress.message = ProgressMessages.Connecting;
                            settings.button.disabled = true;
                        }
                    }
                    break;
                }

                case GameStatuses.makingWagers: {
                    
                    settings.round = true;
                    settings.label = true;
                    const waitingTimeoutHasExpired = moment.utc().isSameOrAfter(moment.utc(gameState.expirationTime), 'second');
                    settings.button.display = true;
                    settings.button.disabled = !waitingTimeoutHasExpired && gameState.participantWagers.length < gameState.participants.length;
                    settings.button.statusForChange = GameStatusesForChange.displayEliminated;
                    settings.progress.show = true;
                    settings.progress.percentage = (gameState.participantWagers.length / gameState.participants.length) * 100;

                    switch (gameState.participantWagers.length) {
                        case 0:
                            settings.progress.message = ProgressMessages.NoWagers;
                            break;
                        case gameState.participants.length:
                            settings.progress.message = ProgressMessages.AllWagersIn;
                            break;
                        default:
                            settings.progress.message = ProgressMessages.MakingWagers;
                            break;
                        }

                    if( gameState.isFinalRound)
                        settings.button.text = ButtonTexts.Proceed
                    else
                        settings.button.text = ButtonTexts.Eliminate;
                    break;
                }

                case GameStatuses.displayEliminated: {
                    settings.progress.show = true;
                    settings.progress.percentage = 100;
                    settings.progress.message = ProgressMessages.AllWagersIn;
                    settings.round = true;
                    settings.label = true;
                    if( gameState.eliminatedParticipantNames && gameState.eliminatedParticipantNames.length > 0 )
                        settings.eliminatedPlayer = true;

                    settings.button.display = true;
                    settings.button.statusForChange = GameStatusesForChange.answering;
                    settings.button.text = ButtonTexts.Question;
                    break;
                }

                case GameStatuses.answering: {
                    settings.round = true;
                    settings.label = true;
                    settings.progress.show = true;
                    settings.progress.percentage = (gameState.participantAnswers.length / gameState.participants.length) * 100;
                    settings.button.display = true;
                    settings.button.text = ButtonTexts.CorrectAnswer;
                    settings.button.statusForChange = GameStatusesForChange.checkingAnswers;
                    settings.question = true;
                    const allAnswered = gameState.participants.length === gameState.participantAnswers.length;
                    settings.progress.message = allAnswered
                        ? ProgressMessages.AllAnswersIn
                        : ProgressMessages.Answering;

                    const waitingTimeoutHasExpired = moment.utc().isSameOrAfter(moment.utc(gameState.expirationTime), 'second');
                    settings.button.disabled = !allAnswered && !waitingTimeoutHasExpired;
                    break;
                }

                case GameStatuses.checkingAnswers: {
                    settings.round = true;
                    settings.label = true;
                    settings.progress.show = true;
                    settings.progress.percentage = 100;
                    settings.progress.message = ProgressMessages.AllAnswersIn;
                    settings.checkingAnswers = true;
                    settings.button.display = true;
                    settings.button.statusForChange = GameStatusesForChange.newRound;
                    settings.button.text = ButtonTexts.NextRound;
                    break;
                }

                case GameStatuses.selectingCategory: {
                    settings.round = true;
                    settings.categorySelecting = true;
                    break;
                }

                case GameStatuses.displayWinner: {

                    settings.displayWinners = true;
                    break;
                }
            }
        }   
        return settings;
    }

    return {
        getControlPageSettings
    }
}