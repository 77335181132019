export enum AppMode {
  Participant = 'Participant',
  Operator = 'Operator',
  Monitor = 'Monitor',
  Audience = 'Audience',
}

const getAppMode = () => {
  if (process.env.REACT_APP_MODE && process.env.REACT_APP_MODE !== 'None')
    return process.env.REACT_APP_MODE as AppMode;
  if (window.location.host.includes('participant')) return AppMode.Participant;
  if (window.location.host.includes('operator')) return AppMode.Operator;
  if (window.location.host.includes('monitor')) return AppMode.Monitor;
  // FIXME: remove default
  // return 'default'
  throw Error(`Unknown app mode for the host ${window.location.host}`);
};

// export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;
// export const WALL_MANAGEMENT_API_URL = process.env.REACT_APP_WALL_MANAGEMENT_API_URL;
export const APP_MODE = getAppMode();
