import { GameStatusesForChange } from "../Models/General/gameStatuses";
import { ErrorCodes } from "../Models/General/technicalErrors";
import { OperatorGameState } from "../Models/Response/operator/gameStatus";
import { BaseRequester } from "./BaseRequester";

export class OperatorRequester extends BaseRequester {

    //  public async AutoSignIn(): Promise<void> {
    //     if (this.apiStorage.apiLogin && this.apiStorage.apiPwd) {
    //         const request = {
    //             Email: this.apiStorage.apiLogin,
    //             Password: this.apiStorage.apiPwd,
    //         } as SignInRequest;
    //         await this.MakePostRequest("/api/auth/signIn", request);
    //     }
    // }

    public async EnterToTheGame(roomCode: number | string ): Promise<Response | undefined> {
        return await this.MakePutRequest(`/api/Operator/enter`, { roomCode });
    }

    public async GetGameState(roomCode: number | string ): Promise<Response | undefined> {
        return await this.MakeGetRequest(`/api/Operator/state`, null, new Headers({ roomCode: roomCode.toString() }));
    }

    public async ChangeGameStatus(status: GameStatusesForChange, roomCode: string ): Promise<Response | undefined> {
       return await this.MakePutRequest(`/api/Operator/status`, { status }, null, new Headers({ roomCode: roomCode }));
    }

    public async DeleteParticipant(name: string, roomCode: string ): Promise<Response | undefined> {
       return await this.MakeDeleteRequest(`/api/Operator/participants/${name}`, null, null, new Headers({ roomCode: roomCode }));
    }
}