import { destroyCookie, parseCookies, setCookie } from "nookies";
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom";
import { DesktopRoomEntrance } from "src/components/roomEntrance";
import { StudioNoInternet } from "src/components/studio/infoScreens/noInternetConnection";
import { StudioTechnicalError } from "src/components/studio/infoScreens/technicalError";
import { Logo } from "src/elements/logo";
import { ErrorCodes } from "src/Logic/Models/General/technicalErrors";
import { MonitorRequester } from "src/Logic/Requesters/MonitorRequester";
import { Page } from "../StudioMonitor/styles";

const GAME_ID = 4;

export const StudioSignInPage = () => {

    const [ roomCode, setRoomCode ] = useState<string | undefined>(undefined);
    const [ technicalError, setTechnicalError ] = useState<ErrorCodes | number | null>( null );
    const [ isNoInternet, setIsNoInternet ] = useState( false );

    const requester = useMemo<MonitorRequester>(() => new MonitorRequester(), []);
    const navigate = useNavigate()

    useEffect(() => {
        const cookies = parseCookies();
        const storedRoomCode = cookies.roomCode;
        if( storedRoomCode )
            destroyCookie( null, 'roomCode');
    }, []);
    
    const handleEnter = () => {
        if( roomCode)
            handleEnterToTheGame( roomCode )
    }

    const handleEnterToTheGame = async (enteredCode: string) => {
        if( !navigator.onLine )
            setIsNoInternet( true );
        else
        try {
            const response = await requester.EnterToTheGame(GAME_ID, enteredCode);
            if ( response )
            { 
                if( response.status === 200) {
                    setCookie(null, 'roomCode', enteredCode);
                    navigate('/game');
                }
                else if( response.status === 400 )
                {
                    const body = await response.json();
                    setTechnicalError(body.errorCode)
                }
                else
                {
                    setTechnicalError( response.status )
                }
            }
        }
        catch( error )
        {
            console.log( error );
        }
    };

    return (
        <Page style={{}}>
            <Logo className='desktopLogo' />
            { !technicalError && !isNoInternet ? 
             <DesktopRoomEntrance
                buttonText="Enter"
                title = 'STUDIO MONITOR'
                onEnterCode={setRoomCode}
                onButtonClick = { handleEnter }
            /> : null }
            { technicalError ? <StudioTechnicalError error = { technicalError } onOk = { () => { setTechnicalError( null )}} /> : null }
            { isNoInternet ? <StudioNoInternet onRetry={() => { setIsNoInternet( false ); }}  /> : null}
        </Page>
    )
}