// Core
import { useEffect, useMemo, useState } from 'react';

// Components
import { AdminTechnicalError } from 'src/components/admin/adminTechnicalError';
import { AdminNoInternet } from 'src/components/admin/noInternet';
import { AudienceScreen } from 'src/components/audience/audienceScreen/audienceScreen';

// Elements
import { Logo } from 'src/elements/logo';

// Requester
import { AudienceRequester } from 'src/Logic/Requesters/AudienceRequester';

// Types
import { AudienceGameState } from 'src/Logic/Models/Response/audience/gameStatus';

// Cookies
import { parseCookies } from 'nookies';

// API
import { getGameState } from 'src/Logic/API/Audience/getGameState';

// Components
import { Page } from './styles';

// Enums
import { ErrorCodes } from 'src/Logic/Models/General/technicalErrors';

// React router
import { useNavigate } from 'react-router-dom';

export const AudiencePage = () => {
    const requester = useMemo<AudienceRequester>(() => new AudienceRequester(), []);

    const [audiencenPageState, setAudiencenPageState] = useState<AudienceGameState>();
    const [technicalError, setTechnicalError] = useState<ErrorCodes | number | null>(null);
    const [roomCode, setRoomCode] = useState<string>();
    const [isNoInternet, setIsNoInternet] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        const cookies = parseCookies();
        const storedRoomCode = cookies.roomCode;
        console.log('stored', storedRoomCode);
        if (storedRoomCode) {
            setRoomCode(storedRoomCode);
        } else navigate('/sign-in');
    }, []);

    useEffect(() => {
        if (!isNoInternet && !technicalError) {
            let timeout = setTimeout(async function run() {
                try {
                    if (roomCode !== undefined) {
                        console.log('Update status...');
                        await getGameState(
                            roomCode,
                            requester,
                            setAudiencenPageState,
                            setTechnicalError,
                            setIsNoInternet,
                            navigate,
                        );
                        console.log('Status updated.');
                    }
                } finally {
                    clearTimeout(timeout);
                    timeout = setTimeout(run, 1000);
                }
            }, 1000);

            return () => {
                if (timeout) clearTimeout(timeout);
            };
        }
    }, [requester, roomCode, technicalError, isNoInternet]);

    return (
        <Page>
            <Logo className="descktopLogo" />
            {audiencenPageState && !technicalError && !isNoInternet ? (
                <AudienceScreen gameState={audiencenPageState} />
            ) : null}
            {technicalError ? (
                <AdminTechnicalError error={technicalError} onOk={() => setTechnicalError(null)} />
            ) : null}
            {isNoInternet ? (
                <AdminNoInternet
                    onRetry={() => {
                        setIsNoInternet(false);
                    }}
                />
            ) : null}
        </Page>
    );
};
