// Svg
import PurpleSvg from '../../../../assets/images/players-icons/purple.svg';
import PinkSvg from '../../../../assets/images/players-icons/pink.svg';
import GreenSvg from '../../../../assets/images/players-icons/green.svg';
import YellowSvg from '../../../../assets/images/players-icons/yellow.svg';
import GraySvg from '../../../../assets/images/players-icons/gray.svg';
import BlueSvg from '../../../../assets/images/players-icons/blue.svg';
import SeaBlueSvg from '../../../../assets/images/players-icons/sea-blue.svg';
import BurgurySvg from '../../../../assets/images/players-icons/burgundy.svg';
import RedSvg from '../../../../assets/images/players-icons/red.svg';
import OrangeSvg from '../../../../assets/images/players-icons/orange.svg';

export const getPaticipantIcon = (participantId: number) => {

    switch( participantId % 10) {
        case 0: {
            return PurpleSvg;
        }
        case 1: {
            return PinkSvg;
        }
        case 2: {
            return GreenSvg;
        }
        case 3: {
            return YellowSvg;
        }
        case 4: {
            return GraySvg;
        }
        case 5: {
            return BlueSvg;
        }
        case 6: {
            return SeaBlueSvg;
        }
        case 7: {
            return BurgurySvg;
        }
        case 8: {
            return RedSvg;
        }
        case 9: {
            return OrangeSvg;
        }
        default: {
            return '';
        }
    }
}